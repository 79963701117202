import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { RHFTextField } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import RHFRadioGroups from "src/components/hook-form/RHFRadioGroups";
import RHFSelector from "src/components/hook-form/RHFSelector";
import Iconify from "src/components/Iconify";
import ProvinceSelector from "src/components/selector/Province";
import { DUTY_DEFAULT_OP, GENDER_DEFAULT_OP, PASSPORT_DEFAULT_OP } from "src/config";
import FormInputImage from "src/pages/RegistrantForms/Section/InputsCore/Image";
import UniversitySelector from "src/pages/university/selector";
import universityCategorySelector from 'src/pages/universityCategory/selector';


const HamrahSection = ({
    index,
    HelpCompanionData,
    ShowZamenFormHamrah,
    beforRegister = false
}) => {

    const { watch, getValues } = useFormContext();

    const [ShowZamenForm, setShowZamenForm] = useState(false)
    // const [beforeRegistrant, setBeforeRegistrant] = useState(!!HelpCompanionData[index]?.name)
    const [beforeRegistrant, setBeforeRegistrant] = useState(beforRegister)


    // const hamrahStage = index === 0 ? "همسر" : `فرزند ${index}`;
    const hamrahStage = HelpCompanionData[index]?.sectionName;

    useEffect(() => {
        setBeforeRegistrant(beforRegister)
    }, [beforRegister])

    return (
        <>
            <Box sx={{
                px: 3,
            }}>


                {(!beforeRegistrant || ShowZamenFormHamrah) &&

                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: "center",
                                p: 2,
                                backgroundColor: 'grey.main',
                                borderRadius: '8px',
                                mb: 4
                            }}
                        >
                            <Typography>اطلاعات ضامن {hamrahStage}</Typography>

                            {/* {beforeRegistrant &&
                                <Button variant="outlined" onClick={() => setShowZamenForm(!ShowZamenForm)}>
                                    {ShowZamenForm ? ("فرم تعهدنامه " + hamrahStage) : ("نمایش و ویرایش اطلاعات ضامن " + hamrahStage)}
                                </Button>
                            } */}

                        </Box>
                        <Grid sx={{}} container spacing={3}>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].name`}
                                    label={"نام"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].last_name`}
                                    label={"نام خانوادگی"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].shenasname`}
                                    label={"شماره شناسنامه"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].codeMeli`}
                                    label={"کدملی"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].father_name`}
                                    label={"نام پدر"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFDatePicker
                                    name={`companions[${index}].birthDate`}
                                    label={"تاریخ تولد"}
                                    pickerProps={{
                                        maxDate: new Date(),
                                    }}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].phone`}
                                    label={"تلفن همراه"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <RHFTextField
                                    name={`companions[${index}].address`}
                                    label={"آدرس"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].postalCode`}
                                    label={"کدپستی"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].homePhone`}
                                    label={"تلفن ثابت(به همراه کد)"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].sodorShenasnameh`}
                                    label={"محل صدور شناسنامه"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].job`}
                                    label={"شاغل در"}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>

                                <FormInputImage
                                    name={`companions[${index}].scanCartMeli`}
                                    key={`companions_${index}_scanCartMeli`}


                                    data={{
                                        need_confirm: HelpCompanionData[index]?.scanCartMeli?.need_confirm,
                                        client_inputs: HelpCompanionData[index]?.scanCartMeli ? [HelpCompanionData[index]?.scanCartMeli] : [],
                                        ...HelpCompanionData[index]?.scanCartMeli,
                                        label: "اسکن شناسنامه یا کارت ملی ضامن",
                                        client_id: HelpCompanionData[index]?.id
                                    }}

                                    index={0}
                                    description={`فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
                                        حداکثر حجم قابل قبول برای هر عکس: 500 کیلوبایت
                                        فرمت های قابل قبول: JPEG، JPG، PNG`}


                                // accept={"*"}
                                />
                            </Grid>
                        </Grid>
                    </>

                }


                {(beforeRegistrant && !ShowZamenFormHamrah) &&
                    <>
                        <Grid sx={{}} container spacing={3}>

                            <Grid item xs={12} md={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: "center",
                                        p: 2,
                                        backgroundColor: 'grey.main',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <Typography>ارسال فرم تعهدنامه ضامن {hamrahStage}</Typography>

                                    {/* {beforeRegistrant &&
                                        <Button variant="outlined" onClick={() => setShowZamenForm(!ShowZamenForm)}>
                                            {ShowZamenForm ? "فرم تعهدنامه" : "نمایش و ویرایش اطلاعات ضامن " + hamrahStage}
                                        </Button>
                                    } */}

                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormInputImage
                                    name={`companions[${index}].taahodName`}
                                    key={`companions_${index}_taahodName`}

                                    data={{
                                        need_confirm: HelpCompanionData[index]?.taahodName?.need_confirm,
                                        client_inputs: HelpCompanionData[index]?.taahodName ? [HelpCompanionData[index]?.taahodName] : [],
                                        ...HelpCompanionData[index]?.taahodName,
                                        label: "فرم تعهد نامه",
                                        client_id: HelpCompanionData[index]?.id
                                    }}

                                    description={`فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
                                    حداکثر حجم قابل قبول برای هر عکس: 500 کیلوبایت
                                    فرمت های قابل قبول: gif، JPG، PNG`}

                                    index={0}
                                // accept={"*"}

                                />
                            </Grid>
                        </Grid>
                    </>
                }

            </Box>
        </>
    );
}

export default HamrahSection;