import { Stack, Typography } from "@mui/material";

const CompeletePageLottery = () => {
    return (
        <>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                    minHeight:"50vh"
                }}
            >
                <Typography
                    sx={{
                        fontWeight:800,
                        fontSize:32,
                        textAlign:"center"
                    }}
                >
                    مهلت ثبت نام تمام شده است 
                    <br />
                    لطفا تا پایان قرعه کشی منتظر بمانید
                </Typography>
            </Stack>
        </>
    );
}

export default CompeletePageLottery;