import { Button, Checkbox, Container, FormControlLabel, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import { SITE_ADDRESS } from "src/config";
import useSettings from "src/hooks/useSettings";
import { setRulesItem } from "src/utils/rules";

const RulesPage = () => {
    const { themeStretch } = useSettings();

    const navigate = useNavigate();

    const [AcceptRuleUser, setAcceptRuleUser] = useState(false)

    const handleChange = (event) => {
        setAcceptRuleUser(event.target.checked);
    };

    const handleAcceptRules = () => {

        if (!AcceptRuleUser) {
            toast.error("برای ادامه باید قوانین را بپذیرید!")
            return
        }

        setRulesItem();
        navigate("/")
    }


    return (
        <>
            <Page
                sx={{
                    py: 3,
                }}
                title={"صفحه قوانین"}
            >

                <Container maxWidth={themeStretch ? false : 'lg'}>

                    {/* <HeaderBreadcrumbs heading={"صفحه قوانین"} /> */}

                    <Typography sx={{ mb: 2, fontSize: 23, fontWeight: 700, textAlign: "center" }}>
                        ثبت نام اولیه عمره دانشگاهیان 1403
                    </Typography>

                    <Typography sx={{ mb: 2 }}>
                        فرهیخته گرامی <br />
                        با سلام و احترام <br />
                        خواهشمند است قبل از اقدام به ثبت نام نکات ذيل را به دقت مطالعه فرمائيد

                    </Typography>
                    <Typography sx={{ mb: 2, fontWeight: 700 }}>
                        شرایط ثبت نام :
                    </Typography>
                    <ul className="dash-list">
                        <li style={{ marginBottom: 8 }}>
                            امکان ثبت نام برای کلیه دانشجویان، اعضای هیئت علمی و اساتید حق التدریس دانشگاه های سراسر کشور به صورت انفرادی یا همراه خانواده(همسر و فرزندان)، فراهم می باشد
                        </li>
                        {/* <li style={{ marginBottom: 8 }}>
                            توجه: امکان ثبت نام و اعزام کارکنان دانشگاه و همچنین پدر و مادر و سایر بستگان به همراه دانشگاهیان وجود ندارد
                        </li> */}
                        <li style={{ marginBottom: 8 }}>
                            توجه: امکان ثبت نام و اعزام کارکنان محترم دانشگاه و همچنین پدر و مادر و سایر بستگان در کاروان های عمره دانشجویی وجود ندارد
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            ثبت نام از تاريخ 1403/08/28 آغاز و تا تاريخ 1403/09/07 ادامه خواهد داشت و نتایج قرعه كشي پس از اتمام ثبت نام از طريق پایگاه اطلاع رسانی لبیک به اطلاع برگزيدگان خواهد رسيد .
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            با توجه به اينكه ملاك شناسايي زائرين عمره گزار كد ملي مي باشد، در وارد كردن كد ملي براي ثبت نام دقت فرمائيد. در صورتيكه كد ملي وارد شده با كد ملي شخصی تناقض داشته باشد ؛امكان ویرایش آن وجود نخواهد داشت و در صورت پذيرفته شدن درقرعه كشي ‌از اعزام ايشان جلوگيري بعمل مي آيد .
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            امکان ثبت نام و اعزام دانشجویان خارجی مشغول تحصیل در کشور وجود ندارد.
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            کلیه اساتید و دانشجویان ثبت نامی می بایست در سال جاری مشغول تحصیل یا تدریس در دانشگاه باشند
                        </li>
                    </ul>

                    <Typography sx={{ my: 1 }}>
                        توجه: ارائه گواهی اشتغال به تدریس یا حکم کارگزینی برای اساتید و همچنین گواهی اشتغال به تحصیل برای دانشجویان؛ جز مدارک اصلی و ضروری جهت اعزام می باشد
                    </Typography>
                    <ul className="dash-list">
                        <li style={{ marginBottom: 8 }}>
                            مدت اعتبار گذرنامه می بایست از زمان سفر؛ حداقل 6 ماه (تا شهریور 1404)باشد.
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            برای ثبت نام عمره دانشگاهیان نیازی به داشتن فیش ودیعه ثبت نام عمره نمی باشد.
                        </li>
                    </ul>

                    <Typography sx={{ my: 2, fontWeight: 700 }}>
                        هزینه سفر و زمان اعزام :
                    </Typography>

                    <ul className="dash-list">
                        <li style={{ marginBottom: 8 }}>
                            هزینه سفر عمره دانشگاهیان در سال جاری در حدود 43  الی 45 میلیون تومان (بر اساس ایستگاه پروازی) می باشد.
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            تسهیلات (وام) در نظر گرفته شده برای عمره گزاران حدود 40تا50 درصد کل هزینه سفر می باشد
                        </li>
                    </ul>

                    <Typography sx={{ my: 1 }}>
                        توجه: مابقی هزینه تا سقف مبلغ نهایی می بایست نقدا توسط شخص پرداخت شود. همچنین به فرزندان تسهیلات تعلق نمی گیرد.
                    </Typography>

                    <ul className="dash-list">
                        <li style={{ marginBottom: 8 }}> 
                            زمان اعزام کاروان های دانشگاهیان از
                            <Typography component={"span"} color={"error"} sx={{ml:"3px"}}>
                                 یکم بهمن 1403 
                            </Typography>
                             <span> لغایت </span> 
                            <Typography component={"span"} color={"error"} sx={{mr:"3px"}}>
                                سی ام بهمن 1403
                            </Typography>
                            خواهد بود، لذا دانشگاهیان محترم با درنظرگرفتن محدوديت برنامه دانشگاهی خود اقدام به ثبت نام نمايند .
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            اعزام دانشگاهیان صرفاً از ایستگاه های پروازی تهران، مشهد، اصفهان، کرمان و بندرعباس خواهد بود
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            ایستگاه پروازی، تاریخ پرواز و انتخاب کاروان توسط ستاد عمره و عتبات دانشگاهیان تعیین خواهد شد و امکان تغییر یا جابه جائی وجود نخواهد داشت .
                        </li>
                    </ul>

                    <Typography sx={{ my: 2, fontWeight: 700 }} color={"error"}>
                        قابل توجه دانشجویان و اساتید متاهل 
                    </Typography>

                    <ul className="dash-list">
                        <li style={{ marginBottom: 8 }}>
                           زوج های دانشجو که تاریخ عقد آنها در دوسال اخیر باشد یا هر دو دانشجو باشند در اولویت قرعه کشی قرار می گیرند.ضمنا ثبت نام افرادی که هر دو نفر دانشجو هستند فقط یکبار در سامانه امکان پذیر است.
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            هر شخص می بایست با شماره همراه مجزا و غیر تکراری ثبت نام نماید. به همین منظور، جهت ثبت نام فرزندان زیر 18 سال از سیم کارت دوم والدین استفاده گردد.
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            در هنگام ثبت نام، تعداد همراهان به طور دقیق انتخاب گردد. در صورت پذیرفته شدن در قرعه کشی امکان ویرایش تعداد همراهان مقدور نمی باشد.
                        </li>
                        <li style={{ marginBottom: 8 }}>
                            بنا بر اعلام سازمان حج و زیارت؛ هزینه پرداختی گروه سنی  2تا12 سال معادل  90درصد هزینه بزرگسال، و زیر 2سال 15 درصد هزینه بزرگسال می باشد. ضمناً منظور از بزرگسال سن بالای 12 سال می باشد.
                        </li>
                    </ul>

                    <Typography sx={{ my: 1 }}>
                        توجه: گروه سنی از زمان روز پرواز(بهمن ماه) محاسبه می گردد.
                    </Typography>

                    <Typography sx={{ my: 2, fontWeight: 700 }} color={"error"}>
                        تذکر مهم 
                    </Typography>
                    <Typography sx={{ mb: 2}}>
                        برای ارتباط با ستاد عمره و عتبات دانشگاهیان و طرح مطالب و مسائل خود تنها از طریق پایگاه اطلاع رسانی لبیک و بخش ارتباط با ما اقدام نمایید و تا حدامکان از تماس تلفنی و مراجعه حضوری خودداری نمایید.
                    </Typography>
                    <Typography component={"a"} href={`${SITE_ADDRESS}/faq`} target="_blank" sx={{ mb: 2}} color={"blue"}>
                        پاسخ به پرسش های متداول
                    </Typography>




                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mt={3}>
                        <FormControlLabel
                            control={<Checkbox color="success" onChange={handleChange} checked={AcceptRuleUser} />}
                            label="قوانین را میپذیرم"
                            labelPlacement="end"
                        />

                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleAcceptRules}
                        >
                            ادامه
                        </Button>

                    </Stack>



                </Container>

            </Page>
        </>
    );
}

export default RulesPage;