import { useTranslation } from 'react-i18next';
import { ControlPoint, Edit } from '@mui/icons-material';
import { Box, Button, Grid, Modal, Typography, Alert } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalLayout from '../../../components/ModalLayout';
import AddFormSectionInput from './AddFormSectionInput';
import Enum from '../enum';
import SectionInput from './SectionInput';
import QuestionComponent from '../../../components/ui/Question/index';
import axiosInstance from '../../../utils/axios';
import api from '../../../services/api';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import RHFTextField from 'src/components/hook-form/RHFTextField';

const Section = ({ inputs }) => {
  const [inputState, setInputState] = useState();

  const handleNewInput = (data, isEdit) => {
    console.log('* * * handleNewInput : ', { data });
    let newData = inputState?.length ? [...inputState] : [];
    if (!isEdit) newData.push(data);
    else {
      newData = newData?.map((x) => (x.id === data?.id ? { ...data } : { ...x }));
    }
    setInputState(newData);
  };

  const handleRemoveInput = (data) => {
    console.log('* * * handleRemoveInput : ', { data });
    setInputState((p) => p?.filter((x) => x?.id !== data?.id));
  };

  const urlParams = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (inputs?.length) setInputState(inputs);
  }, [inputs]);
  // ------------------------------------------------------------------------------ modal ref

  const ModalLayoutRemoveInputSectionRef = useRef();
  const ModalLayoutRemoveInputRef = useRef();
  const ModalSectionFormRef = useRef();
  const ModalAddFormRef = useRef();

  // ------------------------------------------------------------------------------ modal toggle

  // const handleToggleSectionModal = () => ModalSectionFormRef.current.show((p) => !p, { data });
  const handleToggleInputModal = (params) => ModalAddFormRef.current.show((p) => !p, { data: params?.data });
  const handleRemoveInputSectionModal = (params) =>
    ModalLayoutRemoveInputSectionRef.current.show((p) => !p, { data: params?.data });
  // ------------------------------------------------------------------------------ Mutation service
  const deletingInputSection = async (param) => {
    const resData = await axiosInstance.delete(`${Enum.api.input}/${param?.id}`);

    return {
      resData,
      param,
    };
  };

  // ------------------------------------------------------------------------------ Mutation
  // ---------------------------------------

  const deleteInput = useMutationCustom({
    url: deletingInputSection,
    name: `${Enum?.api?.form}_update`,
    // invalidQuery: `${Enum?.api?.form}_get_${urlParams?.id}`,
    invalidQueries: [`${Enum?.api?.form}_get_${urlParams?.id}`, `${Enum?.api?.form}_gets`],
    onSuccess: (data) => {
      console.log('* * * deleteInput onSuccess : ', { data });
      toast.success(t('question.delete.successfully'));
      handleRemoveInputSectionModal();
      ModalAddFormRef.current.show(false);
      handleRemoveInput(data?.param);
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  // ---------------------------------------

  // ------------------------------------------------------------------------------ handler
  const handleDelete = (data) => {
    console.log('* * * handleDelete : ', { data });
    deleteInput.mutate(data);
  };

  return (
    (inputState || [])?.length &&
    <Box
      sx={{
        mt: 7,
      }}
    >
      {/* <Box>
          <Typography sx={{ my: 3 }}>{'لینک شبکه های اجتماعی کاروان'}</Typography>
        </Box> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <ModalLayout ref={ModalLayoutRemoveInputSectionRef}>
          <QuestionComponent
            {...{
              loading: deleteInput.isLoading,
              title: t('question.delete.title'),
              description: t('question.delete.description'),
              button: {
                confirm: {
                  label: t('question.delete.yes'),
                  onClick: (_, data) => handleDelete(data),
                },
                reject: {
                  label: t('question.delete.no'),
                  onClick: () => handleRemoveInputSectionModal(),
                },
              },
            }}
            onClose={handleRemoveInputSectionModal}
          />
        </ModalLayout>

        {/* ----------------------------------------------------- */}
        <ModalLayout ref={ModalAddFormRef}>
          <AddFormSectionInput
            // section={data}
            onSuccess={handleNewInput}
            onRemove={(x) => handleRemoveInputSectionModal({ data: x })}
          />
        </ModalLayout>
        {/* ----------------------------------------------------- */}
        {/* <ModalLayout ref={ModalSectionFormRef}>
        <AddSection section={data} />
      </ModalLayout> */}
        {/* ----------------------------------------------------- */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            backgroundColor: 'background.neutral',
            borderRadius: '8px',
          }}
        >
          <Typography>{'اطلاعات تکمیلی'}</Typography>
          {/* <Edit
            onClick={handleToggleSectionModal}
            sx={{
              cursor: 'pointer',
            }}
          /> */}
        </Box>
        <Grid sx={{}} container spacing={3}>
          {inputState?.length ? (
            inputState?.map((x) => (
              <Grid key={x.id} item xs={12} md={12} mx={2}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: "center",
                    p: 2,
                    backgroundColor: 'grey.main',
                    borderRadius: '8px',
                  }}
                >
                  <Typography>{x?.label}</Typography>
                  

                </Box>

                <Typography sx={{mt:2}}>{x.value}</Typography>
                {/* <SectionInput
                  data={x}
                  onEdit={() => handleToggleInputModal({ data: x })}
                  onRemove={() => handleRemoveInputSectionModal({ data: x })}
                /> */}
                {/* <RHFTextField label={x?.label} value={x.value} /> */}
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Alert severity="info">{'موردی یافت نشد!'}</Alert>
            </Grid>
          )}
        </Grid>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: 2,
            borderTop: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          <Typography>
            {
              ''
              // 'سایر امکانات'
            }
          </Typography>

          <Button onClick={handleToggleInputModal} variant="outlined" color="success">
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <Typography>{'افزودن مورد جدید'}</Typography>
              <ControlPoint />
            </Box>
          </Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Section;
