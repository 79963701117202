import { Route, Routes } from 'react-router';
import Enum from './enum';
import ById from './ById';
import FirstRegistryForm from './forms/first';
import DutyRegisteryForm from './forms/duty';

const RegistrantForms = () => {
  // <div>{'RegistrantForms'}</div>
  return (
    <Routes>
      <Route path={Enum.routes.byId} element={<ById />} />
      <Route path={Enum.routes.firstForm} element={<FirstRegistryForm />} />
      <Route path={Enum.routes.nezamForm} element={<DutyRegisteryForm />} />
    </Routes>
  );
};

export default RegistrantForms;
