import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import LoadingScreen from "src/components/LoadingScreen";
import { setSettings } from "src/redux/slices/settings";
import api from "src/services/api";
import axiosInstance from "src/utils/axios";

const SettingsProviders = ({children}) => {

    const settings = useSelector(store => store?.settings)
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleGetSettings = async () => {
        try {
            const {data} = await axiosInstance.get(api.settings.base);
            console.log({settings : data})
            dispatch(setSettings(data));

            if(data?.find(item  => item?.key === "site_status")?.value === "منتظر قرعه کشی"){
                navigate("/wait")
                return
            }

            if(data?.find(item  => item?.key === "site_status")?.value === "بسته"){
                navigate("/maintenance")
                return
            }
            
        } catch (error) {
            console.log(error)
            dispatch(setSettings([]));
        }
    }

    useEffect(() => {
        
        handleGetSettings()
    },[location.pathname])

    return (
        settings?.isLoading ? <LoadingScreen /> : children
    );
}

export default SettingsProviders;