import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Modal } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import useQueryString from '../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import TableComponent from '../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../hooks/useTable';
import { hasAccess } from '../../../permission/utiles';

const List = ({ access }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString({ sort: 'createdAt@desc' });

  // ---------------------------------------------------------------------
  const getApi = async () =>
    axiosInstance.get(`${Enum?.api?.base}`, { params: { ...queryString, travel_id: queryParams.travel } });

  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);
  // ---------------------------------------------------------------------

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // -------------------------------------------------------- query Get
  const read = (params) => axiosInstance.post(`${Enum?.api?.base}`, params);
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
  };
  // const onErrorMutating = (error) => {
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const resQuery = useMutationCustom({
    url: read,
    name: `${Enum?.api?.base}_update`,
    invalidQueries: [`${Enum?.api?.base}_list`, `${Enum?.api?.base}_all_list`],
    onSuccess: onSuccessMutating,
    //  onError: onErrorMutating,
  });
  const handleMarkAllAsRead = () => {
    resQuery.mutate({ notification_id: data?.data?.map?.((x) => x?.notification?.id) });
  };

  const handleMarkAsRead = (data) => {
    resQuery.mutate({ notification_id: [data?.notification?.id] });
  };
  // ------------------------------------------------------- query Get
  const { data: { data = {} } = {}, isLoading } = useQueryCustom({
    name: `${Enum?.api?.base}_all_list`,
    url: getApi,
    params: { ...queryString },
  });
  // ------------------------------------------------------- query delete

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_list`, `${Enum?.api?.base}_all_list`],
    onSuccess: () => {
      toast.success(t('successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  // const checkAccess = useMemo(() => {
  //   return {
  //     // update: hasAccess(access?.update),
  //     delete: hasAccess(access?.delete),
  //     create: hasAccess(access?.create),
  //     confirm: hasAccess(access?.confirm),
  //   };
  // }, [access]);

  const title = `${Enum.title.name[1]} `;

  const handleShowFullNotificarion = (data) =>{
    console.log({data})
    // if(!data?.seen){
    //   handleMarkAsRead(data)
    // }
  }

  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root}`,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    isLoading: resQuery?.isLoading,
    onRead: handleMarkAsRead,
    // onRead: handleShowFullNotificarion,
    // disabled: checkAccess,
  });

  console.log({ tableOptions, data });

  return (
    <Page title={`${title}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'داشبورد', href: Enum.routes.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          // action={
          //   <>
          //     <Button
          //       variant="contained"
          //       color={'success'}
          //       startIcon={<Iconify icon={'eva:done-all-fill'} />}
          //       onClick={handleMarkAllAsRead}
          //     >
          //       {'خواندن همه'}
          //     </Button>
          //   </>
          // }
        />
        <TableComponent
          active={{
            // selectable: true,
            rowNumber: true,
          }}
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
        />{' '}
      </Container>
    </Page>
  );
};

export default List;
