import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import RoleSelector from 'src/pages/role/selector';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import FormProvider from '../../../components/hook-form/FormProvider';
import validation from './validation';
import Enum from '../enum';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import WaitingBox from '../../../components/WaitingBox/index';
import RHFTextareaField from '../../../components/hook-form/RHFTextareaField';
import RHFSelector from '../../../components/hook-form/RHFSelector';
import { Interweave } from 'interweave';

const Show = ({ onClose }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;

  const navigate = useNavigate();

  const backUrl = `${Enum.routes.root}`;
  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.base, params);
  const updating = (params) => axiosInstance.put(`${Enum?.api?.base}/${paramId}`, params);
  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);
  const read = (params) => axiosInstance.post(`${Enum?.api?.base}`, params);

  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(backUrl);
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };

  // ---------------------------------------

  const resQuery = useMutationCustom({
    url: read,
    name: `${Enum?.api?.base}_update`,
    invalidQueries: [`${Enum?.api?.base}_list`, `${Enum?.api?.base}_all_list`],
    // onSuccess: onSuccessMutating,
    //  onError: onErrorMutating,
  });

  const onSuccess = (resData) => {
    if(!resData?.data?.seen){
      resQuery.mutate({ notification_id: [resData?.data?.notification?.id] });
    }
  }
  
  const dataById = useQueryCustom({
    name: `getById_${Enum?.api?.base}_${paramId}`,
    url: getById,
    onSuccess: onSuccess,
    enabled: !!paramId,
  });


  console.log({ queryParams });


  // const agentsOptions
  return dataById.isLoading ? (
    <WaitingBox />
  ) : (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <HeaderBreadcrumbs
        back={`${Enum.routes.root}`}
        heading={
          "مشاهده پیام"
          // paramId ? t('editAuthor', { author: Enum.title.name[0] }) : t('addAuthor', { author: Enum.title.name[0] })
        }
      >
        {/* <Typography sx={{ mt: 1 }}>{resQuery?.data?.data?.name}</Typography> */}
      </HeaderBreadcrumbs>
      
      <Box>
        {/*<Typography>
          {dataById?.data?.data?.notification?.message || ""}
        </Typography>*/}
        <Interweave content={dataById?.data?.data?.notification?.message || ""} />
      </Box>
      
    </Box>
  );
};

export default Show;
