import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import useCollapseDrawer from './hooks/useCollapseDrawer';
import { PATH_DASHBOARD } from './routes/paths';
import GlobalStyle from './theme/GlobalStyle';
import ErrorBoundary from './layouts/ErrorBoundary';
import SettingsProviders from './contexts/settings';

// ----------------------------------------------------------------------

export default function App() {
  const admin = useSelector((store) => store.admin);
  const navigate = useNavigate();
  console.log({ admin });

  console.warn = () => {};


  // useEffect(() => {
  //   if(admin?.data?.has_won_lottery === 3 || admin?.data?.has_won_lottery === "3"){
  //     navigate("/wait")
  //   }
  // },[admin])

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <SettingsProviders>
            <RtlLayout>
              <ToastContainer
                hideProgressBar
                rtl
                toastClassName={'siteTempToast'}
                position="top-right"
                theme="light"
                // autoClose={false}
              />
              <NotistackProvider>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  <ChartStyle />
                  <GlobalStyle />
                  {/* <Settings /> */}
                  <ScrollToTop />
                  <ErrorBoundary>
                    <Router admin={admin} />
                  </ErrorBoundary>
                </MotionLazyContainer>
              </NotistackProvider>
            </RtlLayout>
          </SettingsProviders>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
