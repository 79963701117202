/* eslint-disable camelcase */
import { LoadingButton } from "@mui/lab";
import { Box, Button, Tooltip } from '@mui/material';
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import api from "src/services/api";
import axiosInstance from "src/utils/axios";
import { createPortal } from 'react-dom';
import { Lightbox } from 'react-modal-image';


/* eslint-disable arrow-body-style */
const HelpLightBoxBtn = ({setShowModal}) => {
    const queryParams = useParams();
    const { id, travel } = queryParams;



    return (
        <>
            
            <Box
                onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
            >
                <Tooltip title={"راهنمایی بارگزاری عکس"} arrow>
                    <Button variant="contained"
                    onClick={() => setShowModal(true)}
                    color={'success'} sx={{
                        position:"relative",
                        zIndex:999,
                        mt:1
                    }}>
                        راهنمایی و توضیحات بیشتر
                    </Button>
                </Tooltip>

            </Box>
        </>
    );
}

export default HelpLightBoxBtn;