/* eslint-disable arrow-body-style */
/* eslint-disable no-sequences */
import { useMemo, useState } from 'react';
import { RHFUploadSingleFile } from '../../../../components/hook-form';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';
import TaahodNameBtn from '../donwloadTaahod';
import HelpLightBoxBtn from '../helpImage';
import { toast } from "react-toastify";
import api from "src/services/api";
import axiosInstance from "src/utils/axios";
import { createPortal } from 'react-dom';
import { Lightbox } from 'react-modal-image';

const FormInputImage = ({
  name,
  data,
  disabled,
  index,
  isHistory,
  description = `فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
حداکثر حجم قابل قبول برای هر عکس: 500 کیلوبایت
فرمت های قابل قبول: JPEG، JPG، PNG،` ,
  ...other
}) => {
  const [showModal, setShowModal] = useState();

  // const options=useMemo(()=>{
  //     // const param= JSON.parse(data?.options||'{}');
  //     return {
  //         array: data?.options?.items?.map((x)=>({label:x.label,value:x.label})),
  //         data:data?.options
  //     }

  // },[data]);

  return (
    !data?.options?.hideThisItem &&
    <>
      {createPortal(
        // () as React.ReactNode
        showModal ? (
          <Lightbox
            onClose={() => setShowModal((p) => !p)}
            // photoIndex={0}
            isOpen={showModal}
            // images={[isString(file) ? file : file.preview]}
            medium={"/images/sakha.jpg"}
            large={"/images/sakha.jpg"}
          />
        ) : (
          ''
        ),
        document.body
      )}
      <RHFUploadSingleFile
        name={name}
        title={data?.label}
        description={data?.label === "عکس پرسنلی گذرنامه" ? `فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
حداکثر حجم قابل قبول برای هر عکس: 200 کیلوبایت
فرمت های قابل قبول: JPEG، JPG، PNG،` :description}
        disabled={disabled || (data?.client_inputs?.[index || 0]?.confirm_status === 1)}
        required={data?.options?.required}
        status={
          <>
            {data?.need_confirm && (
              //  isHistory &&
              <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
            )}
            {data?.label === "فرم تعهد نامه" && (
              <>
                <TaahodNameBtn client_id={data?.client_id} />
              </>
            )}
            {data?.label === "عکس پرسنلی گذرنامه" &&
              <>
                <HelpLightBoxBtn setShowModal={setShowModal}/>
              </>
            }
          </>
        }
        {...other}
      />
    </>
  );
};

export default FormInputImage;
