/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */
import { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Container, Modal, Button, Typography, CircularProgress, Grid, FormHelperText, Stack } from '@mui/material';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { CheckCircle, ControlPoint } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import CaravanSelector from 'src/components/selector/Caravan';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import axiosInstance from '../../../../utils/axios';
import Enum, { optionsSelect } from '../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../hooks/useSettings';
import WaitingBox from '../../../../components/WaitingBox/index';
import SectionPublic from '../../Section';
import FormProvider from '../../../../components/hook-form/FormProvider';
import { formTypesObject, travelTypeObject } from '../../../../enumeration';
import errorsText from '../../../../utils/errorsText';
import api from '../../../../services/api';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import persianToEnglishNumber from '../../../../utils/persianToEnglishNumber';
import { fDateForApi, timeIsAfterToday } from '../../../../utils/formatTime';
import ModalLayout from '../../../../components/ModalLayout';
import QuestionComponent from '../../../../components/ui/Question/index';
import CaravanInput from '../../Section/InputsCore/Caravan';
import { newDate } from '../../../../utils/dating';
import { persianToLatin } from 'src/utils';
import { useSelector } from 'react-redux';
import { RHFTextField } from 'src/components/hook-form';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import RHFRadioGroups from 'src/components/hook-form/RHFRadioGroups';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import ProvinceSelector from 'src/components/selector/Province';
import universityCategorySelector from 'src/pages/universityCategory/selector';
import UniversitySelector from 'src/pages/university/selector';
import { DUTY_DEFAULT_OP, GENDER_DEFAULT_OP, PASSPORT_DEFAULT_OP, SITE_ADDRESS, TASHAROF_DEFAULT_OP } from 'src/config';
import FormSchema from './validation';
import HamrahSection from './component/hamrah';
import axiosInstanceV2 from 'src/utils/axiosV2';
import Iconify from 'src/components/Iconify';
import RemoveNullObjectValue from 'src/utils/RemoveNullObjectValue';
import Swal from 'sweetalert2';
import CustomSwal from 'src/utils/swal';

const FirstRegistryForm = () => {

    const ModalLayoutQuestionInputRef = useRef();
    const ModalLayoutSuccessRef = useRef();

    const admin = useSelector((store) => store.admin.data);
    const travelMainObj = useSelector((store) => store.travel.data);

    const [SearchParams] = useSearchParams();

    const [TravelDataFromStore, setTravelDataFromStore] = useState({})

    const defaultValues = useMemo(
        () => ({
            id: -1,
            name: '',
            last_name: "",
            shenasname: "",
            codeMeli: "",
            father_name: "",
            birthDate: "",
            gender: "",
            email: "",
            phone: "",
            student_number: "",
            type_of_employment_number: "",
            marride_status: null,
            tashrof_be_sorat: null,
            gozarnameh_status: null,
            duty_system: null,
            mazhab: null,
            need_vam: null,
            sabegh_tasharof: null,
            home_phone: null,
            has_hamrah: null,
            hamrah_count: null,
            marrid_date: "",
            student_marriage: null,
            provoince_university: null,
            type_university: null,
            university_code: null,
            kunkor: null,
            degree: null,
            // educational_qualification: null,
            type_of_employment: null,
            type_of_employment_state: null,

            companions: [],
        }),

        []
    );


    const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params);
    const handleSuccessModal = (params) => ModalLayoutSuccessRef.current?.show((p) => !p, params, { disableBackDrop: true });

    const queryParams = useParams();
    const { id, travel } = queryParams;
    const { themeStretch } = useSettings();
    const { t } = useTranslation();
    const [validationState, setValidationState] = useState({});
    const [TravelApi, setTravelApi] = useState();
    const [inputsById, setInputsById] = useState();
    const [beforeRegistrant, setBeforeRegistrant] = useState();
    const [successBeforeRegistrant, setSuccessBeforeRegistrant] = useState();
    const [caravanOptions, setCaravanOptions] = useState();

    const [HideForWemen, setHideForWemen] = useState(false)

    const [companionSectionId, setCompanionSectionId] = useState();

    const [HamrahDataArr, setHamrahDataArr] = useState([]);

    const [DisableInputs, setDisableInputs] = useState(false)

    const [TasharofOptionArr, setTasharofOptionArr] = useState(TASHAROF_DEFAULT_OP)
    const [DisabledHasHamrah, setDisabledHasHamrah] = useState(false)

    const methods = useForm({
        resolver: yupResolver(FormSchema),
        // mode: 'onChange',
        mode: 'all',
        shouldUnregister: false,
        defaultValues: defaultValues
    });

    const {
        reset,
        watch,
        control,
        setValue,
        setError,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting },
    } = methods;

    // const formValue = watch();

    // const genderWatcher = watch('gender');
    // const mirrageStatusWatcher = watch('marride_status');
    // const tasharofWatcher = watch('tashrof_be_sorat');
    // const hasHamragWatcher = watch('has_hamrah');
    // const hamrahCountWatcher = watch('hamrah_count');
    const genderWatcher = useWatch({ control, name: 'gender' });
    const mirrageStatusWatcher = useWatch({ control, name: 'marride_status' });
    const tasharofWatcher = useWatch({ control, name: 'tashrof_be_sorat' });
    const hasHamragWatcher = useWatch({ control, name: 'has_hamrah' });
    const hamrahCountWatcher = useWatch({ control, name: 'hamrah_count' });
    const companions = useWatch({ control, name: 'companions' });
    // const companions = watch("companions") || [];


    const isError = Object.keys(errors)?.length;

    // console.log({ errors })

    // ----------------------
    const creating = (params) => axiosInstanceV2.post(api.public.travelRegister.base, params);
    const updating = (params) => axiosInstanceV2.put(`${api.public.travelRegister.base}/${travel}`, params);
    const getById = () => axiosInstanceV2.get(`${api.form.base}/${travel}`);
    const getTravelById = () => axiosInstance.get(`${api.travelRegister.base}/${travel}`);
    // ----------------------

    // submit Mutate

    const onSuccessMutating = () => {
        handleSuccessModal();
    };

    // const handleSuccess = () => {
    //     if (isAuthenticated) resQuery.refetch();
    // };
    // const handleSuccessFinally = () => {
    //     if (!isAuthenticated) navigate(PATH_AUTH.login);
    // };

    // submit Mutate (END)

    const { isLoading, mutate } = useMutationCustom({
        url: beforeRegistrant ? updating : creating,
        name: `${api.public.travelRegister.base}_add_first`,
        // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
        onSuccess: onSuccessMutating,
        //  onError: onErrorMutating,
    });

    const handleGetUserFormData = (data) => {

        const userFormData = data?.data?.clients?.[0];
        const userFormClientsData = data?.data?.clients;

        if (userFormData) {
            const userInputStructure = {
                id: userFormData?.id,
                name: userFormData?.name ? userFormData?.name : "",
                last_name: userFormData?.last_name ? userFormData?.last_name : "",
                shenasname: userFormData?.shenasname_number ? userFormData?.shenasname_number : "",
                codeMeli: userFormData?.code_melli,
                father_name: userFormData?.father_name ? userFormData?.father_name : "",
                birthDate: userFormData?.birthdate ? new Date(userFormData?.birthdate) : "",
                gender: userFormData?.gender ? { label: userFormData?.gender, value: userFormData?.gender } : "",
                email: userFormData?.email ? userFormData?.email : "",
                phone: userFormData?.phone,
                student_number: userFormData?.student_number ? userFormData?.student_number : "",
                marride_status: userFormData?.marital_status ? { label: userFormData?.marital_status, value: userFormData?.marital_status } : null,
                tashrof_be_sorat: userFormData?.tasharof_be_soorat ? { label: userFormData?.tasharof_be_soorat, value: userFormData?.tasharof_be_soorat } : null,
                gozarnameh_status: userFormData?.passport_status ? { label: userFormData?.passport_status, value: userFormData?.passport_status } : null,
                duty_system: userFormData?.nezam_vazife_status ? { label: userFormData?.nezam_vazife_status, value: userFormData?.nezam_vazife_status } : null,
                mazhab: userFormData?.religion ? { label: userFormData?.religion, value: userFormData?.religion } : null,
                need_vam: userFormData?.need_vam ? { label: userFormData?.need_vam, value: userFormData?.need_vam } : null,
                sabegh_tasharof: userFormData?.sabeghe_tasharof ? { label: userFormData?.sabeghe_tasharof, value: userFormData?.sabeghe_tasharof } : null,
                home_phone: userFormData?.home_phone ? userFormData?.home_phone : "",
                has_hamrah: userFormData?.has_hamrah ? { label: userFormData?.has_hamrah, value: userFormData?.has_hamrah } : null,
                hamrah_count: userFormData?.hamrah_count ? { label: userFormData?.hamrah_count, value: userFormData?.hamrah_count } : null,
                marrid_date: userFormData?.marriage_date ? new Date(userFormData?.marriage_date) : "",
                student_marriage: userFormData?.student_marriage ? { label: userFormData?.student_marriage, value: userFormData?.student_marriage } : null,
                provoince_university: userFormData?.university_city ? { label: userFormData?.university_city, value: userFormData?.university_city } : null,
                type_university: userFormData?.university_category ? { label: userFormData?.university_category, value: userFormData?.university_category } : null,
                university_code: userFormData?.university_name ? { label: userFormData?.university_name, value: userFormData?.university_name } : null,
                kunkor: userFormData?.konkoor ? { label: userFormData?.konkoor, value: userFormData?.konkoor } : null,
                degree: userFormData?.education_level ? { label: userFormData?.education_level, value: userFormData?.education_level } : null,
                // educational_qualification: null,
                type_of_employment: userFormData?.hokm_kargozini ? { label: userFormData?.hokm_kargozini, value: userFormData?.hokm_kargozini } : null,
                type_of_employment_state: userFormData?.university_level ? { label: userFormData?.university_level, value: userFormData?.university_level } : null,
                type_of_employment_number: userFormData?.hokm_kargozini_number ? userFormData?.hokm_kargozini_number : "",
                companions: []
            }

            if (parseInt(userFormData?.hamrah_count, 10) > 0) {
                for (let i = 1; i < userFormClientsData.length; i++) {
                    const element = userFormClientsData[i];
                    const currentCompanionData = {
                        id: element?.id,
                        name: element?.name,
                        last_name: element?.last_name,
                        shenasname: element?.shenasname_number,
                        code_meli: element?.code_melli,
                        father_name: element?.father_name,
                        birthDate: new Date(element?.birthdate),
                        gender: { label: element?.gender, value: element?.gender },
                        email: element?.email,
                        phone: element?.phone,
                        student_number: element?.student_number ? element?.student_number : "",
                        marride_status: { label: element?.marital_status, value: element?.marital_status },
                        tashrof_be_sorat: { label: element?.tasharof_be_soorat, value: element?.tasharof_be_soorat },
                        gozarnameh_status: { label: element?.passport_status, value: element?.passport_status },
                        duty_system: element?.nezam_vazife_status ? { label: element?.nezam_vazife_status, value: element?.nezam_vazife_status } : null,
                        mazhab: { label: element?.religion, value: element?.religion },
                        need_vam: element?.need_vam ? { label: element?.need_vam, value: element?.need_vam } : null,
                        sabegh_tasharof: { label: element?.sabeghe_tasharof, value: element?.sabeghe_tasharof },
                        home_phone: element?.home_phone,
                        student_marriage: element?.student_marriage ? { label: element?.student_marriage, value: element?.student_marriage } : null,
                        provoince_university: element?.university_city ? { label: element?.university_city, value: element?.university_city } : null,
                        type_university: element?.university_category ? { label: element?.university_category, value: element?.university_category } : null,
                        university_code: element?.university_name ? { label: element?.university_name, value: element?.university_name } : null,
                        kunkor: element?.konkoor ? { label: element?.konkoor, value: element?.konkoor } : null,
                        degree: element?.education_level ? { label: element?.education_level, value: element?.education_level } : null,
                        is_Student: { label: element?.is_student, value: element?.is_student },
                        // educational_qualification: null,
                        type_of_employment: element?.hokm_kargozini ? { label: element?.hokm_kargozini, value: element?.hokm_kargozini } : null,
                        type_of_employment_state: element?.university_level ? { label: element?.university_level, value: element?.university_level } : null,
                    }
                    userInputStructure.companions.push(currentCompanionData)
                }
            }

            setBeforeRegistrant(true)
            reset(userInputStructure)
        }

    }
    // const getTravelData = useQueryCustom({
    //     url: getTravelById,
    //     name: `${api.public.travelRegister.base}_getById_travel`,
    //     params: { id: travel },
    //     // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
    // });
    const firstFormOfTravel = useQueryCustom({
        url: getById,
        name: `${api.public.travelRegister.base}_getById_first`,
        params: { id: travel },
        // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
        onSuccess: handleGetUserFormData,
        enabled: !!admin?.id
        //  onError: onErrorMutating,
    });

    const isDuplicateInArray = (arr, key) => {
        const seen = new Set();

        for (const item of arr) {
            if (seen.has(item[key])) {
                return true; // Duplicate found
            }
            seen.add(item[key]);
        }

        return false; // No duplicates
    };


    const onSubmit = async () => {

        const formValue = getValues();

        handleQuestionModal();

        console.log({
            formValue,
            // reqData
        })

        const reqData = {
            travel_id: travel,
            name: formValue.name,
            last_name: formValue.last_name,
            student_number: formValue.tashrof_be_sorat?.value !== "استاد دانشگاه" ? persianToEnglishNumber(formValue.student_number) : undefined,
            code_melli: persianToEnglishNumber(formValue.codeMeli),
            phone: persianToEnglishNumber(formValue.phone),
            shenasname_number: persianToEnglishNumber(formValue.shenasname),
            father_name: formValue.father_name,
            birthdate: persianToEnglishNumber(fDateForApi(formValue.birthDate)),
            gender: formValue.gender?.value,
            email: formValue.email !== "" ? formValue.email : undefined,
            marital_status: formValue.marride_status?.value,
            tasharof_be_soorat: formValue.tashrof_be_sorat?.value,
            religion: formValue.mazhab?.value,
            home_phone: persianToEnglishNumber(formValue.home_phone),
            passport_status: formValue.gozarnameh_status?.value,
            nezam_vazife_status: formValue?.gender?.value === "مذکر" ? formValue.duty_system?.value : undefined,
            need_vam: formValue.need_vam?.value,
            sabeghe_tasharof: formValue.sabegh_tasharof?.value,
            marriage_date: (formValue.tashrof_be_sorat?.value === "دانشجویان متاهل" && formValue.marride_status?.value === "متاهل") ? persianToEnglishNumber(fDateForApi(formValue.marrid_date)) : undefined,
            student_marriage: (formValue.tashrof_be_sorat?.value === "دانشجویان متاهل" && formValue.marride_status?.value === "متاهل") ? formValue.student_marriage?.value : undefined,
            has_hamrah: (formValue.tashrof_be_sorat.value?.search("مجرد") === -1 && formValue.has_hamrah?.value) ? formValue.has_hamrah?.value : "خیر",
            hamrah_count: (formValue.tashrof_be_sorat.value?.search("مجرد") === -1 && formValue.hamrah_count?.value && formValue.has_hamrah?.value === "بله") ? persianToEnglishNumber(formValue.hamrah_count?.value) : 0,
            nesbat_to_head: "سرگروه",
            university_city: formValue.provoince_university?.label,
            university_category: formValue.type_university?.label,
            university_name: formValue.university_code?.label,
            konkoor: formValue.kunkor?.value,
            education_level: formValue.degree?.value,
            is_student: (formValue.tashrof_be_sorat?.value === "استاد دانشگاه" || formValue.tashrof_be_sorat?.value === "کارمند") ? "خیر" : "بله",
            hokm_kargozini: formValue.tashrof_be_sorat?.value === "استاد دانشگاه" ? formValue?.type_of_employment?.value : undefined,
            university_level: formValue.tashrof_be_sorat?.value === "استاد دانشگاه" ? formValue?.type_of_employment_state?.value : undefined,
            hokm_kargozini_number: formValue.tashrof_be_sorat?.value === "استاد دانشگاه" ? persianToEnglishNumber(formValue.type_of_employment_number) : undefined,
        }

        // reqData = RemoveNullObjectValue(reqData);


        if (
            parseInt(formValue.hamrah_count?.value, 10) > 0 &&
            formValue.has_hamrah.value === "بله" &&
            formValue.tashrof_be_sorat.value?.search("مجرد") === -1
        ) {
            reqData.array = [];

            for (let i = 0; i < parseInt(formValue.hamrah_count?.value, 10); i++) {

                if (persianToEnglishNumber(formValue.codeMeli) === persianToEnglishNumber(formValue?.companions[i]?.code_meli)) {
                    // toast.error(`کد ملی وارد شده با یکی از همراهان مشابه است ، لطفا آن را ویرایش کنید`)
                    CustomSwal.fire({
                        title: '',
                        text: `کد ملی وارد شده با یکی از همراهان مشابه است ، لطفا آن را ویرایش کنید`,
                        icon: 'error',
                        confirmButtonText: 'بستن'
                    })
                    return
                }

                if (persianToEnglishNumber(formValue.phone) === persianToEnglishNumber(formValue?.companions[i]?.phone)) {
                    // toast.error(`شماره همراه وارد شده با یکی از همراهان مشابه است ، لطفا آن را ویرایش کنید`)
                    CustomSwal.fire({
                        title: '',
                        text: `شماره همراه وارد شده با یکی از همراهان مشابه است ، لطفا آن را ویرایش کنید`,
                        icon: 'error',
                        confirmButtonText: 'بستن'
                    })
                    return
                }

                const element = {
                    'name': formValue?.companions[i]?.name,
                    'last_name': formValue?.companions[i]?.last_name,
                    'code_melli': persianToEnglishNumber(formValue?.companions[i]?.code_meli),
                    'phone': persianToEnglishNumber(formValue?.companions[i]?.phone),
                    // 'shenasname_number' : persianToEnglishNumber(formValue?.companions[i]?.name) ,
                    'father_name': formValue?.companions[i]?.father_name,
                    'birthdate': persianToEnglishNumber(fDateForApi(formValue?.companions[i]?.birthDate)),
                    'gender': formValue?.companions[i]?.gender?.value,
                    'passport_status': formValue?.companions[i]?.gozarnameh_status?.value,
                    'nezam_vazife_status': formValue?.companions[i]?.gender?.value === "مذکر" ? formValue?.companions[i]?.duty_system?.value : undefined,
                    'need_vam': i == 0 ? formValue?.companions[i]?.need_vam?.value : "نیستم",
                    'nesbat_to_head': i === 0 ? "همسر" : "فرزند",
                    'university_city': formValue?.companions[i]?.is_Student?.value === "بله" ? formValue?.companions[i]?.provoince_university?.label : undefined,
                    'university_category': formValue?.companions[i]?.is_Student?.value === "بله" ? formValue?.companions[i]?.type_university?.label : undefined,
                    'university_name': formValue?.companions[i]?.is_Student?.value === "بله" ? formValue?.companions[i]?.university_code?.label : undefined,
                    'konkoor': formValue?.companions[i]?.is_Student?.value === "بله" ? formValue?.companions[i]?.kunkor?.value : undefined,
                    'education_level': formValue?.companions[i]?.is_Student?.value === "بله" ? formValue?.companions[i]?.degree?.value : undefined,
                    "student_number": formValue?.companions[i]?.is_Student?.value === "بله" ? persianToEnglishNumber(formValue?.companions[i]?.student_number) : undefined,
                    'is_student': formValue?.companions[i]?.is_Student?.value,
                }

                reqData.array.push(RemoveNullObjectValue(element));

            }
        }

        if (isDuplicateInArray(formValue?.companions, "code_meli")) {
            // toast.error("کدملی همراه ها مشابه یک دیگر هستند لطفا آن را ویرایش کنید.")
            CustomSwal.fire({
                title: '',
                text: `کدملی همراه ها مشابه یک دیگر هستند لطفا آن را ویرایش کنید.`,
                icon: 'error',
                confirmButtonText: 'بستن'
            })
            return
        }

        if (isDuplicateInArray(formValue?.companions, "phone")) {
            // toast.error("شماره همراه ،  همراه ها مشابه یک دیگر هستند لطفا آن را ویرایش کنید.")
            CustomSwal.fire({
                title: '',
                text: `شماره همراه ،  همراه ها مشابه یک دیگر هستند لطفا آن را ویرایش کنید.`,
                icon: 'error',
                confirmButtonText: 'بستن'
            })
            return
        }

        mutate(reqData);

    }



    const handleFilterTasharofBeSorate = () => {

        let helpOptions = TASHAROF_DEFAULT_OP;

        if (getValues("hamrah_count")?.value) {
            if (parseInt(getValues("hamrah_count")?.value, 10) >= 1) {
                setValue(`companions[0].gender`, genderWatcher?.value === "مذکر" ? { label: "مونث", value: "مونث" } : { label: "مذکر", value: "مذکر" })
            }
        }

        if (genderWatcher?.value && genderWatcher?.value === "مذکر") {
            helpOptions = helpOptions.filter(item => item?.value?.search("دختر") === -1)
        } else if (genderWatcher?.value && genderWatcher?.value === "مونث") {
            helpOptions = helpOptions.filter(item => item?.value?.search("پسر") === -1)
        }

        // console.log({ asdasda: mirrageStatusWatcher })
        if (mirrageStatusWatcher?.value && mirrageStatusWatcher?.value === "مجرد") {
            helpOptions = helpOptions.filter(item => item?.value?.search("متاهل") === -1)
        }

        if (helpOptions?.findIndex(item => item?.value === tasharofWatcher?.value) === -1) {
            setValue("tashrof_be_sorat", null)
        }

        if (tasharofWatcher?.value?.search("مجرد") > -1 || (mirrageStatusWatcher?.value && mirrageStatusWatcher?.value === "مجرد")) {
            setValue("hamrah_count", null);
            setValue("has_hamrah", null);
            setValue("companions", []);

        }

        if (mirrageStatusWatcher?.value === "متاهل" && tasharofWatcher?.value === "دانشجویان متاهل") {
            if (getValues("hamrah_count")?.value) {
                if (parseInt(getValues("hamrah_count")?.value, 10) < 1) {
                    setValue("hamrah_count", { label: "1", value: "1" });
                }
            } else {
                setValue("hamrah_count", { label: "1", value: "1" });
            }
            setValue("has_hamrah", { label: "بله", value: "بله" });
            setDisabledHasHamrah(true)
        } else {
            setDisabledHasHamrah(false)
        }

        setTasharofOptionArr(helpOptions)
    }

    const handleCheckUserCanEditInputs = (inputLabel = "") => {
        // console.log({travelMainObj : TravelDataFromStore})
        if(admin?.has_won_lottery == 6){
            if(
                inputLabel === "کدملی" ||
                inputLabel === "تلفن همراه" 
            ){
                setDisableInputs(true)
                return true
            }
            if(admin?.add_by_admin){
                if(TravelDataFromStore?.type_finish_at && timeIsAfterToday(TravelDataFromStore?.type_finish_at)){
                    setDisableInputs(true)
                    return true
                }
                setDisableInputs(false)
                return false
            }
            setDisableInputs(true)
            return true
        }
        if(
            inputLabel === "کدملی" ||
            inputLabel === "تلفن همراه" 
        ){
            if(admin?.id){
                setDisableInputs(true)
                return true
            }
        }
        setDisableInputs(false)
        return false
    }


    useEffect(() => {
        if(Object.values(travelMainObj)?.length > 0){
            Object.values(travelMainObj).forEach((value , index) => {
                const selectedTravel = value?.find(item => item?.id == travel)
                // console.log({setTravelDataFromStore : selectedTravel})
                if(selectedTravel){
                    setTravelDataFromStore(selectedTravel)
                }
            })
        }
    }, [travelMainObj])

    useEffect(() => {
        if(admin?.id && TravelDataFromStore){
            handleCheckUserCanEditInputs()
        }
    }, [admin, TravelDataFromStore])
    useEffect(() => {
        handleFilterTasharofBeSorate()
    }, [genderWatcher, mirrageStatusWatcher])

    useEffect(() => {
        handleFilterTasharofBeSorate()
    }, [mirrageStatusWatcher, tasharofWatcher])

    useEffect(() => {
        if (hasHamragWatcher?.value !== "بله") {
            setValue("hamrah_count", null);
            setValue("companions", []);
        }else if(hasHamragWatcher?.value === "بله" && (!hamrahCountWatcher?.value || hamrahCountWatcher?.value == 0)){
            setValue("hamrah_count", { label: "1", value: "1" });
        }
    }, [hasHamragWatcher])


    // Watch `hamrah_count` and adjust `companions` array accordingly
    useEffect(() => {
        const count = hamrahCountWatcher?.value || 0;

        if (companions.length < count) {
            // Add companions to match the count
            setValue("companions", [...companions, ...Array(count - companions.length).fill({
                need_vam: count > 1 ? { label: "نیستم", value: "نیستم" } : null
            })]);
        } else if (companions.length > count || tasharofWatcher?.value?.search("مجرد") > -1) {
            // Remove excess companions
            setValue("companions", companions.slice(0, count));
        }

        if (count > 0) {
            if (getValues("hamrah_count")?.value) {
                if (parseInt(getValues("hamrah_count")?.value, 10) >= 1) {
                    setValue(`companions[0].gender`, genderWatcher?.value === "مذکر" ? { label: "مونث", value: "مونث" } : { label: "مذکر", value: "مذکر" })
                }
            }
        }
    }, [hamrahCountWatcher]);

    useEffect(() => {
        if (SearchParams?.get("code_melli")) {
            setValue("codeMeli", SearchParams?.get("code_melli"))
        }
        if (SearchParams?.get("phone")) {
            setValue("phone", SearchParams?.get("phone"))
        }

    }, [SearchParams])


    return (
        <>
            {firstFormOfTravel?.isLoading ? <WaitingBox /> :
                <>
                    <Page
                        sx={{
                            py: 3,
                        }}
                        title={"ثبت نام اولیه"}
                    >

                        <ModalLayout ref={ModalLayoutQuestionInputRef}>
                            <QuestionComponent
                                {...{
                                    title: beforeRegistrant ? 'آیا از تغییر اطلاعات مطمئن هستید؟' : 'آیا از تایید ثبت نام مطمئن هستید؟',
                                    description: beforeRegistrant
                                        ? 'پس از تایید اطلاعات جدید شما در لیست زائرین قرار خواهد گرفت . آیا ادامه میدهید؟'
                                        : 'پس از تایید اطلاعات شما در لیست زائرین قرار خواهد گرفت . آیا ادامه میدهید؟',
                                    button: {
                                        confirm: {
                                            label: 'question.yesProcess',
                                            loading: isLoading,
                                            onClick: (_, data) => onSubmit(data),
                                        },
                                        reject: {
                                            label: 'question.no',
                                            onClick: () => handleQuestionModal(),
                                        },
                                    },
                                }}
                            // onClose={() => handleQuestionModal()}
                            />
                        </ModalLayout>

                        <ModalLayout ref={ModalLayoutSuccessRef} onClosed={() => null}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    border: '1px solid #ccc',
                                    borderRadius: 1,
                                    backgroundColor: 'common.white',
                                }}
                            >
                                {/* <Box sx={{
                                    "svg":{
                                        width:"80px",
                                        height:"80px",
                                        color:"success.main"
                                    }
                                }}>
                                    <CheckCircle />
                                </Box> */}
                                <Box
                                    sx={{
                                        mt: '-20px',
                                        svg: {
                                            width: '65px',
                                            height: '65px',
                                            color: 'success.main',
                                            backgroundColor: 'common.white',
                                            borderRadius: '100%',
                                        },
                                    }}
                                >
                                    <CheckCircle />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        p: 3,
                                        gap: 2,
                                        position: 'relative',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            color: 'success.main',
                                        }}
                                    >
                                        {beforeRegistrant
                                            ? 'تغییر اطلاعات شما با موفقیت انجام شد.'
                                            : 'ثبت نام شما با موفقیت انجام شد.'}
                                    </Typography>

                                    {!admin?.add_by_admin ? <Typography variant="h6">{'منتظر قرعه کشی باشید.'}</Typography> : ''}

                                    <a href={SITE_ADDRESS} replace>
                                        <Button
                                            color="success"
                                            variant="contained"
                                            onClick={() => {
                                                // handleSuccessModal();
                                                // completed?.();
                                                // refetch?.();
                                            }}
                                        >
                                            {'بستن'}
                                        </Button>
                                    </a>

                                </Box>
                            </Box>
                        </ModalLayout>
                        <HeaderBreadcrumbs heading={"ثبت نام اولیه"}>
                            {/* <Typography>{getTravelData?.data?.data?.name}</Typography> */}
                        </HeaderBreadcrumbs>
                        <FormProvider methods={methods} onSubmit={handleSubmit(handleQuestionModal)}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        p: 2,
                                        backgroundColor: 'grey.main',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <Typography>اطلاعات فردی</Typography>

                                </Box>
                                <Grid sx={{}} container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"name"}
                                            label={"نام"}
                                            required
                                            // disabled={admin?.has_won_lottery == 6}
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"last_name"}
                                            label={"نام خانوادگی"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"shenasname"}
                                            label={"شماره شناسنامه"}
                                            type={"numbers"}
                                            disableComma
                                            withZero
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"codeMeli"}
                                            label={"کدملی"}
                                            type={"numbers"}
                                            disableComma
                                            withZero
                                            required
                                            disabled={!!admin?.id}
                                            // disabled={handleCheckUserCanEditInputs("کدملی")}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"father_name"}
                                            label={"نام پدر"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFDatePicker
                                            name={"birthDate"}
                                            label={"تاریخ تولد"}
                                            pickerProps={{
                                                maxDate: new Date(),
                                            }}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFRadioGroups
                                            options={GENDER_DEFAULT_OP}
                                            name={"gender"}
                                            label={"جنسیت"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"email"}
                                            label={"پست الکترونیک"}
                                        // required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"phone"}
                                            label={"تلفن همراه"}
                                            required
                                            disabled={!!admin?.id}
                                            // disabled={handleCheckUserCanEditInputs("تلفن همراه")}
                                        />
                                        <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ mt: 1 }}>
                                            <Iconify icon={"fluent:info-12-regular"} sx={{ fontSize: 20, color: "#707070" }} />
                                            <Typography sx={{ fontSize: 13, color: "#707070" }}>تلفن همراه باید به نام فرد باشد</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={
                                                [
                                                    { label: "مجرد", value: "مجرد" },
                                                    { label: "متاهل", value: "متاهل" },
                                                ]
                                            }
                                            name={"marride_status"}
                                            label={"وضعیت تاهل"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={TasharofOptionArr}
                                            name={"tashrof_be_sorat"}
                                            label={"تشرف به صورت"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={PASSPORT_DEFAULT_OP}
                                            name={"gozarnameh_status"}
                                            label={"وضعیت گذرنامه"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    {(
                                        (mirrageStatusWatcher?.value && mirrageStatusWatcher?.value === "متاهل") &&
                                        (tasharofWatcher?.value && tasharofWatcher?.value === "دانشجویان متاهل")
                                    ) &&
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <RHFDatePicker
                                                    name={"marrid_date"}
                                                    label={"تاریخ عقد"}
                                                    pickerProps={{
                                                        maxDate: new Date(),
                                                    }}
                                                    required
                                                    disabled={DisableInputs}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <RHFSelector
                                                    options={[
                                                        { label: "بله", value: "بله" },
                                                        { label: "خیر", value: "خیر" }
                                                    ]}
                                                    name={"student_marriage"}
                                                    label={"ازدواج دانشجویی"}
                                                    required
                                                    disabled={DisableInputs}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {genderWatcher.value === "مذکر" &&
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <RHFSelector
                                                    options={DUTY_DEFAULT_OP}
                                                    name={"duty_system"}
                                                    label={"وضعیت نظام وظیفه"}
                                                    required
                                                    disabled={DisableInputs}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "تشیع", value: "تشیع" },
                                                { label: "تسنن", value: "تسنن" }
                                            ]}
                                            name={"mazhab"}
                                            label={"مذهب"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "هستم", value: "هستم" },
                                                { label: "نیستم", value: "نیستم" }
                                            ]}
                                            name={"need_vam"}
                                            label={
                                                <>
                                                    <Typography component={"span"}>متقاضی دریافت</Typography>
                                                    <Typography component={"span"} sx={{ color: 'red' }}> وام</Typography>
                                                </>
                                            }
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "اعزام با دانشگاهیان", value: "اعزام با دانشگاهیان" },
                                                { label: "اعزام غیر دانشگاهی", value: "اعزام غیر دانشگاهی" },
                                                { label: "نداشته ام", value: "نداشته ام" },
                                            ]}
                                            name={"sabegh_tasharof"}
                                            label={"سابقه تشرف"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <RHFTextField
                                            name={"home_phone"}
                                            label={"تلفن ثابت (به همراه کد)"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>

                                    {tasharofWatcher?.value === "استاد دانشگاه" ?
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <RHFTextField
                                                    name={"type_of_employment_number"}
                                                    label={"شماره حکم کارگزینی"}
                                                    key={"type_of_employment_number"}
                                                    required
                                                    disabled={DisableInputs}
                                                />
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <RHFTextField
                                                    name={"student_number"}
                                                    label={"شماره دانشجویی"}
                                                    key={"student_number"}
                                                    required
                                                    disabled={DisableInputs}
                                                />
                                            </Grid>
                                        </>
                                    }


                                    {(
                                        (mirrageStatusWatcher?.value && mirrageStatusWatcher?.value === "متاهل") &&
                                        (tasharofWatcher?.value && tasharofWatcher?.value?.search("مجرد") === -1)
                                    ) &&
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <RHFSelector
                                                    options={[
                                                        { label: "بله", value: "بله" },
                                                        { label: "خیر", value: "خیر" }
                                                    ]}
                                                    name={"has_hamrah"}
                                                    label={"اعزام با همراه"}
                                                    disabled={DisabledHasHamrah || DisableInputs}
                                                    required
                                                />
                                                <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ mt: 1 }}>
                                                    <Iconify icon={"fluent:info-12-regular"} sx={{ fontSize: 20, color: "#707070" }} />
                                                    <Typography sx={{ fontSize: 13, color: "#707070" }}>همراه فقط شامل همسر و فرزند می باشد</Typography>
                                                </Stack>
                                            </Grid>
                                            {(hasHamragWatcher?.value === "بله") &&
                                                <Grid item xs={12} md={6}>
                                                    <RHFSelector
                                                        options={[
                                                            { label: "1", value: "1" },
                                                            { label: "2", value: "2" },
                                                            { label: "3", value: "3" },
                                                            { label: "4", value: "4" }
                                                        ]}
                                                        name={"hamrah_count"}
                                                        label={"تعداد همراه"}
                                                        required
                                                        disabled={DisableInputs}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                </Grid>

                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                    my: 4
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        p: 2,
                                        backgroundColor: 'grey.main',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <Typography>اطلاعات دانشگاهی</Typography>

                                </Box>
                                <Grid sx={{}} container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <ProvinceSelector.Element
                                            name={"provoince_university"}
                                            label={tasharofWatcher?.value === "استاد دانشگاه" ? "استان محل تدریس" :"استان محل تحصیل"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <universityCategorySelector.Element
                                            name={"type_university"}
                                            label={"نوع دانشگاه"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <UniversitySelector.Element
                                            name={"university_code"}
                                            // provinceId={getValues("provoince_university")?.value || undefined}
                                            // categoryId={getValues("type_university")?.value || undefined}
                                            province_name={getValues("provoince_university")?.label || undefined}
                                            category_name={getValues("type_university")?.label || undefined}
                                            label={"نام دانشگاه"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {/* <Stack direction={"row"} gap={1} alignItems={"center"} sx={{mb:1}}>
                                            <Typography sx={{fontSize:15 , color:"#707070"}}>وضعیت شرکت در کنکور های سراسری سال جاری</Typography>
                                        </Stack> */}
                                        <RHFSelector
                                            options={[
                                                { label: "شرکت نکرده ام", value: "شرکت نکرده ام" },
                                                // { label: "کاردانی", value: "کاردانی" },
                                                { label: "کارشناسی ارشد ناپیوسته", value: "کارشناسی ارشد ناپیوسته" },
                                                { label: "دوره دکتری", value: "دوره دکتری" },
                                                { label: "امتحان جامع علوم پایه پزشکی", value: "امتحان جامع علوم پایه پزشکی" },
                                                { label: "کاردانی به کارشناسی", value: "کاردانی به کارشناسی" },
                                            ]}
                                            name={"kunkor"}
                                            label={"کنکور (وضعیت شرکت در کنکور های سراسری سال جاری)"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <RHFSelector
                                            options={[
                                                { label: "کاردانی", value: "کاردانی" },
                                                { label: "کارشناسی", value: "کارشناسی" },
                                                { label: "کارشناسی ارشد", value: "کارشناسی ارشد" },
                                                { label: "دکتری", value: "دکتری" },
                                            ]}
                                            name={"degree"}
                                            label={"مقطع تحصیلی"}
                                            required
                                            disabled={DisableInputs}
                                        />
                                    </Grid>
                                    {tasharofWatcher?.value === "استاد دانشگاه" &&
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <RHFSelector
                                                    options={[
                                                        { label: "هيئت علمي رسمي تمام وقت", value: "هيئت علمي رسمي تمام وقت" },
                                                        { label: "هيئت علمي نيمه وقت", value: "هيئت علمي نيمه وقت" },
                                                        { label: "حق التدريس", value: "حق التدريس" },
                                                        { label: "پيماني", value: "پيماني" },
                                                        { label: "رسمي-آزمايشي", value: "رسمي-آزمايشي" },
                                                        { label: "ساير احکام", value: "ساير احکام" },
                                                    ]}
                                                    name={"type_of_employment"}
                                                    label={"نوع حکم کارگزینی"}
                                                    required
                                                    disabled={DisableInputs}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <RHFSelector
                                                    options={[
                                                        { label: "استاد", value: "استاد" },
                                                        { label: "استادیار", value: "استادیار" },
                                                        { label: "دانشیار", value: "دانشیار" },
                                                        { label: "حق التدریس", value: "حق التدریس" },
                                                        { label: "مربی", value: "مربی" },
                                                    ]}
                                                    name={"type_of_employment_state"}
                                                    label={"مرتبه دانشگاهی"}
                                                    required
                                                    disabled={DisableInputs}
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>

                            </Box>

                            {(
                                (mirrageStatusWatcher?.value && mirrageStatusWatcher?.value === "متاهل") &&
                                tasharofWatcher?.value?.search("مجرد") === -1 &&
                                parseInt(hamrahCountWatcher?.value, 10) > 0 &&
                                hasHamragWatcher.value === "بله"
                            ) &&
                                <>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 4,
                                            mt: 3
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                p: 2,
                                                backgroundColor: 'grey.main',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <Typography>اطلاعات همراهان</Typography>

                                        </Box>
                                        {Array(parseInt(hamrahCountWatcher?.value, 10)).fill("").map((_, index) =>
                                            <>

                                                <HamrahSection index={index} key={`hamrah-${index}`} HamrahDataArr={HamrahDataArr} setHamrahDataArr={HamrahDataArr} getValues={getValues} />
                                            </>
                                        )}


                                    </Box>

                                </>
                            }




                            {(
                                // admin?.has_won_lottery != 6
                                // !handleCheckUserCanEditInputs("")
                                !DisableInputs
                            ) && 
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: 5,
                                    }}
                                >
                                    <LoadingButton loading={isLoading} type="submit" variant="contained" color={'success'}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 2,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography>{beforeRegistrant ? 'ویرایش اطلاعات' : 'ثبت اطلاعات'}</Typography>
                                            <ControlPoint />
                                        </Box>
                                    </LoadingButton>
                                </Box>
                            }
                            {isError ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'center',
                                        mt: 1,
                                    }}
                                >
                                    <FormHelperText error>{`لطفا تمام فیلد ها را با دقت پر نمایید`}</FormHelperText>
                                </Box>
                            ) : (
                                ''
                            )}
                        </FormProvider>
                    </Page>
                </>
            }
        </>
    );
}

export default FirstRegistryForm;