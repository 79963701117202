import React from 'react';
import Page500 from '../pages/Page500';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and log them
    this.setState({ error, errorInfo });

    // Optionally log the error to an external service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Page500
            error={this.state.error}
            errorInfo={this.state.errorInfo}
          />
        </>
      )
    }

    return this.props.children;
  }
}
