/* eslint-disable radix */
/* eslint-disable no-plusplus */
import { useTranslation } from 'react-i18next';
import { Cancel, ControlPoint, Edit } from '@mui/icons-material';
import { Box, Button, Grid, Modal, Typography, Alert } from '@mui/material';
import { useRef, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { formTypesObject } from '../../../enumeration';
import InputsCore from './InputsCore';
import EPublicForm from '../enum';

const SectionPublic = ({ data, loading, disabled, isHistory, members, isFirst , CampnionArrState , formName , handleDownloadZamenWordBtn }) => {
  const urlParams = useParams();
  const { t } = useTranslation();

  const { control, watch, setValue, getValues, getFieldState } = useFormContext();

  console.log('* * * SectionPublic : ', { data }, data?.label, data?.isCompanions);
  const watchParent = data?.isCompanions && watch(String(data?.parentId));
  if (data?.isCompanions) {
    console.log('* * * SectionPublic isCompanions : ', { watchParent, data }, data?.label);
    if (watchParent?.value === 0 || !watchParent) {
      setValue(data?.id?.toString(), [])
    }
  }
  const newMembers = useMemo(() => {
    // console.log({CampnionArrState , arr : new Array(CampnionArrState?.length || 0).fill({})?.map((x) => ({ id: uuidv4() })) })
    if(formName === "اطلاعات نظام وظیفه"){
      const filterArr = CampnionArrState?.filter(item => !item?.hideThisCompanion);
      if(filterArr?.length === 0){
        return new Array(0).fill({})?.map((x) => ({ id: uuidv4() , sectionName : x?.sectionName }));
      }

      return new Array(filterArr?.length || 0).fill({})?.map((x,i) => {
        if(!filterArr?.[i]?.hideThisCompanion){
          return { id: uuidv4(), sectionName : filterArr?.[i]?.sectionName }
        }
      });
      
    }
    if (members > 0 && formName !== "اطلاعات نظام وظیفه") return new Array(members || 0).fill({})?.map((x) => ({ id: uuidv4() }));
  }, [members]);
  console.log({ newMembers, members , CampnionArrState });
  useEffect(() => {
    try {
      if (parseInt(watchParent?.value) >= 0 && data?.parentId) {
        const values = getValues();
        const current = (values[data?.id] ? [...values[data?.id]] : [])?.map((x) => ({ ...x, id: x.id || uuidv4() }));
        console.log(
          '* * * SectionPublic useEffect: ',
          { watchParent, values, current, parentId: data?.parentId },
          data?.label
        );


        let parentProvinceName;
        let parentProvinceIndex;
        let parentProvinceNames;
        let parentProvinceIndexes;

        // University
        let parentUniversityProvinceName;
        let parentUniversityProvinceIndex;
        let parentUniversityProvinceNames;
        let parentUniversityProvinceIndexes;

        // University Category
        let parentUniversityCategoryName;
        let parentUniversityCategoryIndex;
        let parentUniversityCategoryNames;
        let parentUniversityCategoryIndexes;


        let gender;
        let dutySystem;
        let RelativeToTheApplicant;
        let universityTypeCompanion;
        let universityProvinceCompanion;
        let universityCodeCompanion;

        for (let i = 0; i < data?.inputs?.length; i++) {
          const input = data?.inputs[i];



          const options = input?.options;
          const section = data;
          const companionInfo = {
            count: parseInt(watchParent?.value)
          };

          input.companinonIndex = parseInt(watchParent?.value) - 1;
          input.name = `${section.id}.${parseInt(watchParent?.value) - 1}.${EPublicForm.bseName}${input.id}`;
          input.inputIndex = i;
          input.section = data;
          input.options.required = false;
          input.input = input;


          if (options?.isUniversityCategory) {
            if (section.isCompanions) {

              for (let k = 0; k < companionInfo.count; k += 1) {
                parentUniversityCategoryNames = parentUniversityCategoryNames || {};
                parentUniversityCategoryNames[k] = `${section.id}.${k}.${EPublicForm.bseName + input.id}`;
                parentUniversityCategoryIndexes = parentUniversityCategoryIndexes || {};
                parentUniversityCategoryIndexes[k] = i;
              }
            } else {
              parentUniversityCategoryName = `${section.id}.${EPublicForm.bseName + input.id}`;
              parentUniversityCategoryIndex = i;
            }
          }
          if (options?.isProvince) {
            if (section.isCompanions) {
              for (let k = 0; k < companionInfo.count; k += 1) {
                parentProvinceNames = parentProvinceNames || {};
                parentProvinceNames[k] = `${section.id}.${k}.${EPublicForm.bseName + input.id}`;
                parentProvinceIndexes = parentProvinceIndexes || {};
                parentProvinceIndexes[k] = i;

                if (section.inputs[i]?.label?.trim()?.includes("استان محل تحصیل")) {
                  parentUniversityProvinceNames = parentUniversityProvinceNames || {};
                  parentUniversityProvinceNames[k] = `${section.id}.${k}.${EPublicForm.bseName + input.id}`;
                  parentUniversityProvinceIndexes = parentUniversityProvinceIndexes || {};
                  parentUniversityProvinceIndexes[k] = i;
                }

              }
            } else {
              parentProvinceName = `${section.id}.${EPublicForm.bseName + input.id}`;
              parentProvinceIndex = i;
              if (section.inputs[i]?.label?.trim()?.includes("استان محل تحصیل")) {
                parentUniversityProvinceName = `${section.id}.${EPublicForm.bseName + input.id}`;
                parentUniversityProvinceIndex = i;
              }
            }
          }
          if (options?.isUniversity) {
            if (parentUniversityProvinceName) {
              section.inputs[i].parentUniversityProvinceName = parentUniversityProvinceName;
              section.inputs[parentUniversityProvinceIndex].childCityName = `${section.id}.${EPublicForm.bseName + input.id}`;
            }
            if (parentUniversityCategoryName) {
              section.inputs[i].parentUniversityCategoryName = parentUniversityCategoryName;
              section.inputs[parentUniversityCategoryIndex].childCityName = `${section.id}.${EPublicForm.bseName + input.id}`;
            }
            console.log('ffff', section.isCompanions && parentUniversityProvinceNames);

            if (section.isCompanions && parentUniversityProvinceNames) {
              const parentUniversityProvinceNamesLength = Object.values(parentUniversityProvinceNames);
              for (let k = 0; k < parentUniversityProvinceNamesLength?.length; k += 1) {
                // console.log('ffff', k);
                section.inputs[i].parentUniversityProvinceNames = {
                  ...(section.inputs[i].parentUniversityProvinceNames || {}),
                  [k]: parentUniversityProvinceNames[k],
                };
                section.inputs[parentUniversityProvinceIndexes[k]].childCityNames = {
                  ...(section.inputs[parentUniversityProvinceIndexes[k]].childCityNames || {}),
                  [k]: `${section.id}.${k}.${EPublicForm.bseName + input.id}`,
                };
              }
            }

            if (section.isCompanions && parentUniversityCategoryNames) {
              const parentUniversityCategoryNamesLength = Object.values(parentUniversityCategoryNames);
              for (let k = 0; k < parentUniversityCategoryNamesLength?.length; k += 1) {
                // console.log('ffff', k);
                section.inputs[i].parentUniversityCategoryNames = {
                  ...(section.inputs[i].parentUniversityCategoryNames || {}),
                  [k]: parentUniversityCategoryNames[k],
                };
                section.inputs[parentUniversityCategoryIndexes[k]].childCityNames = {
                  ...(section.inputs[parentUniversityCategoryIndexes[k]].childCityNames || {}),
                  [k]: `${section.id}.${k}.${EPublicForm.bseName + input.id}`,
                };
              }
            }
          }

          if (input?.label?.trim() === "جنسیت همراه") {
            gender = input
          }
          if (input?.label?.trim() === "وضعیت نظام وظیفه همراه") {
            dutySystem = input
          }
          if (input?.label?.trim() === "نسبت با متقاضی") {
            RelativeToTheApplicant = input;
          }
          if (input?.label?.trim() === "نوع دانشگاه همراه") {
            universityTypeCompanion = input
          }
          if (input?.label?.trim() === "استان محل تحصیل همراه") {
            universityProvinceCompanion = input;
          }
          if (input?.label?.trim() === "کد دانشگاه همراه") {
            universityCodeCompanion = input
          }
        }


        const CChilds = [dutySystem]?.filter((x) => x?.name);
        const CChildObject = CChilds?.reduce((prev, curr) => {
          prev[curr?.id] = curr;
          return prev;
        }, {});
        const CChilds1 = [dutySystem]?.filter((x) => x?.name);
        const CChildObject1 = CChilds1?.reduce((prev, curr) => {
          prev[curr?.id] = curr;
          return prev;
        }, {});
        const CChilds2 = [universityTypeCompanion  , universityProvinceCompanion , universityCodeCompanion]?.filter((x) => x?.name);
        const CChildObject2 = CChilds2?.reduce((prev, curr) => {
          prev[curr?.id] = curr;
          return prev;
        }, {});
        const CChilds3 = [universityTypeCompanion  , universityProvinceCompanion , universityCodeCompanion]?.filter((x) => x?.name);
        const CChildObject3 = CChilds3?.reduce((prev, curr) => {
          prev[curr?.id] = curr;
          return prev;
        }, {});


        const events = {
          ...(gender?.id && {
            [gender.id]: {
              value: gender,
              childs: CChildObject,
              afterChange: {
                type: 'resetOptions',
                on: ["مذکر", "مونث"],
                removeWord: ["دختران", "پسران"],
                filterIndex: 0,
                withHidden: false,
                hiddenOn: "مونث",
                hiddenItem: CChildObject1,
                // removeFilter: "وضعیت گذرنامه(حداقل ۶ ماه اعتبار)",
                by: 'child',
              },
              // validation:{
              //   ne:  "خیر",
              //   required:AChilds?.map((x)=>x.name),
              // }
            },
          }),
          ...(RelativeToTheApplicant?.id && {
            [RelativeToTheApplicant.id]: {
              value: RelativeToTheApplicant,
              childs: CChildObject2,
              afterChange: {
                type: 'resetOptions',
                on: ["مذکر", "مونث"],
                removeWord: ["دختران", "پسران"],
                filterIndex: 0,
                withHidden: false,
                hiddenOn: "همسر",
                // showOn:true,
                hiddenItem: CChildObject3,
                checkParentInOtherSection : true,
                checkParentInOtherSectionHidden : "خیر",
                checkParentInOtherSectionData : RelativeToTheApplicant,
                by: 'child',
              },
            },
          }),

        };

        console.log('* * * dynamicly 2 In Section', { dutySystem, gender, events });
        Object.keys(events)?.forEach((x) => {
          const current = events[x];
          console.log('* * * dynamicly 2 In Section', { current });
          if (!current?.value) return;
          const childs = {};
          Object.values(current?.childs || {})?.map((x, i) => {
            // delete current?.childs?.[x?.id]?.section?.[i]?.inputs;
            const { section, ...newChilds } = x || {};
            childs[x?.id] = { ...newChilds, section: { id: section?.id } };
            const options = x?.options || {};
            // try {
            //   options = JSON.parse(x?.options || '{}');
            // } catch (error) {
            //   options = x?.options;
            // }
            if (!options) return;
            if (options.parentId && options.parentId !== current?.value?.name) {
              options.parentId1 = current?.value?.name;
              options.parentInputName1 = `${EPublicForm.bseName}${current?.value?.id}`;
            } else {
              options.parentId = current?.value?.name;
              options.parentInputName = `${EPublicForm.bseName}${current?.value?.id}`;
            }
            options.required = false;
            options.validation = current.validation;
            options.afterChangeParent = current.afterChange;
            // console.log ("* * * dynamicly",{a: x.input.options,options})
            return x;
          });

          const baseInput = data?.inputs?.[current?.value?.inputIndex];
          // console.log('* * * dynamicly 1', { baseInput, current });

          baseInput.options = {
            ...baseInput.options,
            afterChange: current.afterChange,
            childs: childs,
            validation: current.validation,
          };
          console.log('* * * dynamicly 2 In Section', { baseInput, current });
        });


        if (current?.length > parseInt(watchParent?.value)) {
          const newValue = current.slice(0, -(current?.length - parseInt(watchParent?.value)));
          console.log(
            '* * * SectionPublic useEffect: ',
            { newValue },
            -(current?.length - parseInt(watchParent?.value)),
            String(data?.id)
          );
          setValue(String(data?.id), newValue);
        } else {
          const appendedCount = parseInt(watchParent?.value) - current?.length;
          if (appendedCount <= 0 || !appendedCount) return;
          console.log('* * * SectionPublic useEffect: ', { current, appendedCount });
          new Array(appendedCount || 0).fill({})?.map(() => {
            current.push({ id: uuidv4() });
          });
          setValue(String(data?.id), current);
        }

      }
      console.log("* * * SectionPublic useEffect: ", { watchParent, parentId: data?.parentId }, data?.label);
    } catch (error) {
      console.log('* * * SectionPublic useEffect: ', { error });
    }
  }, [watchParent]);

  const onRemoveCompanion = (index) => {
    console.log('* * * SectionPublic onRemoveCompanion', { index, data });
    const values = getValues();
    let current = [...values[data?.id]];
    console.log('* * * SectionPublic onRemoveCompanion', { values, index, data, current });
    current = current?.filter((_, i) => i != index);
    console.log('* * * SectionPublic onRemoveCompanion', { index, data, current });
    setValue(String(data?.id), current);
    setValue(String(data?.parentId), {
      label: watchParent?.value - 1 || 0,
      value: watchParent?.value - 1 || 0
    });
  };
  // || (!isFirst && data?.isCompanions)
  if(formName === "اطلاعات نظام وظیفه" && data?.isCompanions && CampnionArrState?.length === 0) return <></>
  return (data?.isCompanions ? (watchParent?.value > 0 || watchParent > 0) || members > 0 : true) ? (
    <Controller
      name={data?.id?.toString()}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (data?.isCompanions) console.log({ field });
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {/* {formName === "اطلاعات نظام وظیفه" && data?.label === "اطلاعات همراه"} */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
                backgroundColor: 'grey.main',
                borderRadius: '8px',
              }}
            >
              <Typography>{data?.label}</Typography>

              {/* {data?.label?.trim() === "مشخصات ضامن" &&

                <>
                  <a href='/files/letterOfCommitment.docx' download>
                    <Button
                      variant='contained'
                      color='success'
                    >
                      دریافت تعهدنامه
                    </Button>
                  </a>
                </>

              } */}

            </Box>

            {data?.isCompanions ? (
              // true?"":
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                {
                  // new Array(+watchParent || 0)
                  //   .fill({})
                  // watchCompanion
                  (newMembers || field.value)?.map((x, i) => {
                    console.log({checkCom : CampnionArrState?.[i]})
                    return (
                       
                      <Box
                        key={x.id}
                        sx={{
                          p: 3,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            // mb:2,
                            // backgroundColor: 'grey.main',
                            p: 0,
                          }}
                          spacing={3}
                        >
                          <Grid xs={12} item>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 2,
                                backgroundColor: 'grey.main',
                                borderRadius: '8px',
                              }}
                            >
                              {/* <Typography>{`همراه ${i + 1}`}</Typography> */}
                              <Typography>اطلاعات {x?.sectionName ? x?.sectionName : (i === 0 ? "همسر" : `فرزند ${i}`)}</Typography>

                              {data?.isCompanions && isFirst ? (
                                <Cancel
                                  sx={{
                                    cursor: 'pointer',
                                    color: 'grey.500',
                                    '&:hover': {
                                      color: 'grey.700',
                                    },
                                  }}
                                  onClick={() => onRemoveCompanion(i)}
                                />
                              ) : (
                                ''
                              )}
                            </Box>
                          </Grid>

                          {data?.inputs?.map((x) => {
                            let props = {
                              xs: 12,
                              md: 6,
                            };

                            if ([formTypesObject.IMAGE.value, formTypesObject.CHECKBOX.value].includes(x.type)) {
                              props = {
                                ...props,
                                xs: 12,
                                md: 12,
                              };
                            }
                            if (formTypesObject.LOCATION.value === x.type && x?.options?.isAddress) {
                              props = {
                                ...props,
                                xs: 12,
                                md: 12,
                              };
                            }

                            if(formName?.trim() === "بارگزاری مدارک" && CampnionArrState?.[i]?.is_student !== "بله" && x?.label?.trim() === "اسکن گواهی اشتغال به تحصیل همراه"){
                              return <></>
                            }

                            return (
                              <>
                                <InputsCore
                                  data={x}
                                  name={`${data?.id}.${i}.${EPublicForm.bseName || ''}${x?.id}`}
                                  loading={loading}
                                  sectionId={data?.id}
                                  disabled={disabled}
                                  isHistory={isHistory}
                                  index={i}
                                  gridSize={props}
                                  withGridBox
                                />
                              {/* <Grid key={`${data?.id}.${i}.${x.id}`} item {...props}>
                              </Grid> */}
                              </>
                            );
                          })}
                        </Grid>
                      </Box>
                    );
                  })
                }
              </Box>
            ) : (
              <Grid sx={{}} container spacing={3}>
                {data?.inputs?.length ? (
                  data?.inputs?.map((x) => {
                    let props = {
                      xs: 12,
                      md: 6,
                    };

                    if ([formTypesObject.IMAGE.value, formTypesObject.CHECKBOX.value].includes(x.type)) {
                      props = {
                        xs: 12,
                        md: 12,
                      };
                    }
                    if (formTypesObject.LOCATION.value === x.type && x?.options?.isAddress) {
                      props = {
                        xs: 12,
                        md: 12,
                      };
                    }

                    return (
                      <>
                        <InputsCore
                          key={`${data?.id}.${x.id}`}
                          data={x}
                          // name={`name.firstname`}
                          name={`${data?.id}.${EPublicForm.bseName || ''}${x?.id}`}
                          loading={loading}
                          sectionId={data?.id}
                          disabled={disabled}
                          isHistory={isHistory}
                          gridSize={props}
                          withGridBox
                          handleDownloadZamenWordBtn={handleDownloadZamenWordBtn}
                        />
                        {/* <Grid key={`${data?.id}.${x.id}`} item {...props}>
                          </Grid> */}
                      </>
                    );
                  })
                ) : (
                  <Grid item xs={12}>
                    <Alert severity="info">{'موردی یافت نشد!'}</Alert>
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        );
      }}
    />
  ) : (
    <></>
  );
};

export default SectionPublic;
