import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { RHFTextField } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import RHFRadioGroups from "src/components/hook-form/RHFRadioGroups";
import RHFSelector from "src/components/hook-form/RHFSelector";
import Iconify from "src/components/Iconify";
import ProvinceSelector from "src/components/selector/Province";
import { DUTY_DEFAULT_OP, GENDER_DEFAULT_OP, PASSPORT_DEFAULT_OP } from "src/config";
import UniversitySelector from "src/pages/university/selector";
import universityCategorySelector from 'src/pages/universityCategory/selector';
import { timeIsAfterToday } from "src/utils/formatTime";


const HamrahSection = ({
    index,
}) => {
    const queryParams = useParams();
    const { id, travel } = queryParams;

    const admin = useSelector((store) => store.admin.data);

    const { watch, getValues } = useFormContext();
    const travelMainObj = useSelector((store) => store.travel.data);
    const [TravelDataFromStore, setTravelDataFromStore] = useState({})
    const [DisableInputs, setDisableInputs] = useState(false)

    // const genderWatcher = watch(`companions[${index}].gender`);
    // const studentWatcher = watch(`companions[${index}].is_Student`);
    const genderWatcher = useWatch({ name: `companions[${index}].gender` });
    const studentWatcher = useWatch({ name: `companions[${index}].is_Student` });

    const handleCheckUserCanEditInputs = (inputLabel = "") => {
        // console.log({inputLabel , admin})
        if(admin?.has_won_lottery == 6){
            if(
                inputLabel === "کدملی" ||
                inputLabel === "تلفن همراه" 
            ){
                setDisableInputs(true)
                return true
            }
            if(admin?.add_by_admin){
                if(TravelDataFromStore?.type_finish_at && timeIsAfterToday(TravelDataFromStore?.type_finish_at)){
                    setDisableInputs(true)
                    return true
                }
                setDisableInputs(false)
                return false
            }
            setDisableInputs(true)
            return true
        }
        if(
            inputLabel === "کدملی" ||
            inputLabel === "تلفن همراه" 
        ){
            if(admin?.id){
                setDisableInputs(true)
                return true
            }
        }
        setDisableInputs(false)
        return false
    }

    const hamrahStage = index === 0 ? "همسر" : `فرزند ${index}`;

    useEffect(() => {
        if(Object.values(travelMainObj)?.length > 0){
            Object.values(travelMainObj).forEach((value , index) => {
                const selectedTravel = value?.find(item => item?.id == travel)
                // console.log({setTravelDataFromStore : selectedTravel})
                if(selectedTravel){
                    setTravelDataFromStore(selectedTravel)
                }
            })
        }
    }, [travelMainObj])

    useEffect(() => {
        if(admin?.id && TravelDataFromStore){
            handleCheckUserCanEditInputs()
        }
    }, [admin, TravelDataFromStore])

    return (
        <>
            <Box sx={{
                px: 3,
            }}>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 2,
                        backgroundColor: 'grey.main',
                        borderRadius: '8px',
                        mb: 4
                    }}
                >
                    <Typography>اطلاعات {hamrahStage}</Typography>

                </Box>
                <Grid sx={{}} container spacing={3}>

                    <Grid item xs="12" md={6}>
                        <RHFTextField
                            name={`companions[${index}].name`}
                            label={"نام " + hamrahStage}
                            required
                            disabled={DisableInputs}
                        />
                    </Grid>
                    <Grid item xs="12" md={6}>
                        <RHFTextField
                            name={`companions[${index}].last_name`}
                            label={"نام خانوادگی " + hamrahStage}
                            required
                            disabled={DisableInputs}
                        />
                    </Grid>
                    <Grid item xs="12" md={6}>
                        <RHFSelector
                            options={
                                [
                                    { label: "همسر", value: "همسر" },
                                    { label: "فرزند", value: "فرزند" },
                                ]
                            }
                            value={index === 0 ? { label: "همسر", value: "همسر" } : { label: "فرزند", value: "فرزند" }}
                            name={`companions[${index}].nesbat`}
                            label={"نسبت با متقاضی"}
                            disabled
                            required
                        />
                    </Grid>
                    <Grid item xs="12" md={6}>
                        <RHFTextField
                            name={`companions[${index}].code_meli`}
                            label={"کدملی " + hamrahStage}
                            required
                            disabled={DisableInputs}
                        />
                    </Grid>
                    <Grid item xs="12" md={6}>
                        <RHFRadioGroups
                            options={GENDER_DEFAULT_OP}
                            name={`companions[${index}].gender`}
                            label={`جنسیت ${hamrahStage}`}
                            disabled={index === 0 || DisableInputs}
                            required
                        />
                    </Grid>
                    <Grid item xs="12" md={6}>
                        <RHFTextField
                            name={`companions[${index}].father_name`}
                            label={"نام پدر " + hamrahStage}
                            required
                            disabled={DisableInputs}
                        />
                    </Grid>
                    <Grid item xs="12" md={6}>
                        <RHFDatePicker
                            name={`companions[${index}].birthDate`}
                            label={`تاریخ تولد ${hamrahStage}`}
                            pickerProps={{
                                maxDate: new Date(),
                            }}
                            required
                            disabled={DisableInputs}
                        />
                    </Grid>

                    <Grid item xs="12" md={6}>
                        <RHFTextField
                            name={`companions[${index}].phone`}
                            label={"تلفن همراه " + hamrahStage}
                            required
                            disabled={DisableInputs}
                        />
                        <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ mt: 1 }}>
                            <Iconify icon={"fluent:info-12-regular"} sx={{ fontSize: 20, color: "#707070" }} />
                            {index === 0 ?
                                <Typography sx={{ fontSize: 13, color: "#707070" }}>تلفن همراه باید به نام فرد باشد</Typography>
                            :
                                <Typography sx={{ fontSize: 13, color: "#707070" }}>برای فرزندان کمتر از 18 سال شماره دوم والدین وارد گردد.</Typography>
                            }
                        </Stack>
                    </Grid>

                    <Grid item xs="12" md={6}>
                        <RHFSelector
                            options={PASSPORT_DEFAULT_OP}
                            name={`companions[${index}].gozarnameh_status`}
                            label={"وضعیت گذرنامه " + hamrahStage}
                            required
                            disabled={DisableInputs}
                        />
                    </Grid>

                    {index === 0 &&
                        <>
                            <Grid item xs={12} md={6}>
                                <RHFSelector
                                    options={[
                                        { label: "هستم", value: "هستم" },
                                        { label: "نیستم", value: "نیستم" }
                                    ]}
                                    name={`companions[${index}].need_vam`}
                                    defaultValue={index > 0 ? { label: "نیستم", value: "نیستم" } : null}
                                    label={
                                        <>
                                            <Typography component={"span"}>متقاضی دریافت</Typography>
                                            <Typography component={"span"} sx={{ color: 'red' }}> وام</Typography>
                                        </>
                                    }
                                    disabled={index > 0 || DisableInputs}
                                    required
                                />
                            </Grid>
                        </>
                    }


                    {genderWatcher?.value === "مذکر" &&
                        <>
                            <Grid item xs="12" md={6}>
                                <RHFSelector
                                    options={DUTY_DEFAULT_OP}
                                    name={`companions[${index}].duty_system`}
                                    label={"وضعیت نظام وظیفه " + hamrahStage}
                                    required
                                    disabled={DisableInputs}
                                />
                            </Grid>
                        </>
                    }

                    <Grid item xs="12" md={6}>
                        <RHFSelector
                            options={[
                                { label: "بله", value: "بله" },
                                { label: "خیر", value: "خیر" }
                            ]}
                            name={`companions[${index}].is_Student`}
                            label={`آیا ${hamrahStage} دانشجو است`}
                            required
                            disabled={DisableInputs}
                        />
                    </Grid>

                    {studentWatcher?.value === "بله" &&
                        <>
                            <Grid item xs="12" md={6}>
                                <ProvinceSelector.Element
                                    name={`companions[${index}].provoince_university`}
                                    label={"استان محل تحصیل"}
                                    required
                                    disabled={DisableInputs}
                                />
                            </Grid>
                            <Grid item xs="12" md={6}>
                                <universityCategorySelector.Element
                                    name={`companions[${index}].type_university`}
                                    label={"نوع دانشگاه"}
                                    required
                                    disabled={DisableInputs}
                                />
                            </Grid>
                            <Grid item xs="12" md={6}>
                                <UniversitySelector.Element
                                    name={`companions[${index}].university_code`}
                                    // provinceId={getValues(`companions[${index}].provoince_university`)?.value || undefined}
                                    // categoryId={getValues(`companions[${index}].type_university`)?.value || undefined}
                                    province_name={getValues(`companions[${index}].provoince_university`)?.label || undefined}
                                    category_name={getValues(`companions[${index}].type_university`)?.label || undefined}
                                    label={"نام دانشگاه"}
                                    required
                                    disabled={DisableInputs}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RHFTextField
                                    name={`companions[${index}].student_number`}
                                    label={"شماره دانشجویی"}
                                    required
                                    disabled={DisableInputs}
                                />
                            </Grid>
                            <Grid item xs="12" md={6}>
                                <RHFSelector
                                    options={[
                                        { label: "شرکت نکرده ام", value: "شرکت نکرده ام" },
                                        { label: "کاردانی", value: "کاردانی" },
                                        { label: "کارشناسی ارشد ناپیوسته", value: "کارشناسی ارشد ناپیوسته" },
                                        { label: "دوره دکتری", value: "دوره دکتری" },
                                        { label: "امتحان جامع علوم پایه پزشکی", value: "امتحان جامع علوم پایه پزشکی" },
                                        { label: "کاردانی به کارشناسی", value: "کاردانی به کارشناسی" },
                                    ]}
                                    name={`companions[${index}].kunkor`}
                                    label={"کنکور (وضعیت شرکت در کنکور های سراسری سال جاری)"}
                                    required
                                    disabled={DisableInputs}
                                />
                            </Grid>
                            <Grid item xs="12" md={6}>
                                <RHFSelector
                                    options={[
                                        { label: "کاردانی", value: "کاردانی" },
                                        { label: "کارشناسی", value: "کارشناسی" },
                                        { label: "کارشناسی ارشد", value: "کارشناسی ارشد" },
                                        { label: "دکتری", value: "دکتری" },
                                    ]}
                                    name={`companions[${index}].degree`}
                                    label={"مقطع تحصیلی"}
                                    required
                                    disabled={DisableInputs}
                                />
                            </Grid>
                        </>
                    }







                </Grid>
            </Box>
        </>
    );
}

export default HamrahSection;