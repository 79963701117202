import { Box, Grid, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import RHFTextField from '../../../components/hook-form/RHFTextField';
import FormProvider from '../../../components/hook-form/FormProvider';

import validation from './validation';
// import RHFSelectStatic from '../../../components/hook-form/RHFSelectStatic';
import RHFSelector from '../../../components/hook-form/RHFSelector';
import RHFDatePicker from '../../../components/hook-form/RHFDatePicker';
import Enum from '../enum';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import axiosInstance from '../../../utils/axios';
import { fDateForApi } from '../../../utils/formatTime';
import { statusType } from '../../../enumeration/index';
import persianToEnglishNumber from '../../../utils/persianToEnglishNumber';

const AddCourse = ({ onClose }) => {
  const paramId = undefined;
  const { t } = useTranslation();

  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.base, params);
  const updating = (params) => axiosInstance.put(`${Enum?.api?.base}/${paramId}`, params);
  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    reset({
      [validation.fieldNames.type]: null,
      [validation.fieldNames.name]: '',
    });
    onClose();
  };
  // const onErrorMutating = (error) => {
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${Enum?.api?.base}_update`,
    invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    //  onError: onErrorMutating,
  });
  // ---------------------------------------
  const onSuccessDataById = (resData) => {
    console.log({ resData });
    // reset(resetParam);
  };
  const dataById = useQueryCustom({
    name: `getById_${Enum?.api?.base}_${paramId}`,
    url: getById,
    onSuccess: onSuccessDataById,
    enabled: !!paramId,
  });

  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();

    const reqData = {
      [validation.fieldNames.startDate]: persianToEnglishNumber(fDateForApi(values[validation.fieldNames.startDate])),
      [validation.fieldNames.endDate]: persianToEnglishNumber(fDateForApi(values[validation.fieldNames.endDate])),
      [validation.fieldNames.type]: values[validation.fieldNames.type]?.value,
      [validation.fieldNames.name]: values[validation.fieldNames.name],
      [validation.fieldNames.team_count]: +values[validation.fieldNames.team_count],
      [validation.fieldNames.is_active]: values[validation.fieldNames.is_active]?.value,
    };

    console.log({ reqData });
    mutate(reqData);
  };

  return (
    <Box
      sx={{
        maxHeight: '90vh',
        overflow: 'auto',
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
        m: 4,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
      >
        <Typography variant="h5">{'شما در حال ایجاد فرم جدید برای ثبت نام هستید'}</Typography>
      </Box>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <RHFSelector options={Enum.enum.array} name={validation.fieldNames.type} label={'نوع دوره را انتخاب کنید.'} />
          <RHFTextField name={validation.fieldNames.name} label={'نام دوره'} />
          <RHFTextField name={validation.fieldNames.team_count} label={'تعداد اولیه کاروان ها'} type={'number'} />
          <RHFDatePicker name={validation.fieldNames.startDate} label={'تاریخ و زمان شروع ثبت نام'} />
          <RHFDatePicker
            pickerProps={{ minDate: watchStartDate }}
            name={validation.fieldNames.endDate}
            label={'تاریخ و زمان پایان ثبت نام'}
          />
          <RHFSelector options={Object.values(statusType)} name={validation.fieldNames.is_active} label={'وضعیت'} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
          }}
        >
          <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton>

          <LoadingButton fullWidth type="submit" variant="contained" color={'success'} loading={isLoading}>
            {'تایید'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AddCourse;
