import * as yup from 'yup';
import errorsText from '../../../utils/errorsText';

const validation = {
  // defaultValue:{},
  fieldNames: {
    name: 'name',
    status: 'status',
    agents: 'agents',
    sendBy: 'sendBy',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.name]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.agents]: yup.array().required(errorsText.blankError()),
      [validation.fieldNames.sendBy]: yup.array().required(errorsText.blankError()),
    }),
};

export default validation;
