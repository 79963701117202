/* eslint-disable no-lonely-if */
import * as Yup from 'yup';
// import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch } from 'react-redux';
import { OutlinedInput, Stack, Box, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useAuth from '../../../../hooks/useAuth';
import { getAdminProfile } from '../../../../redux/slices/user';
import useTimer from '../../../../hooks/useTimer';
import { toast } from 'react-toastify';
import { useQueryCustom } from 'src/utils/reactQueryHooks';
import axiosInstance from 'src/utils/axios';
import api from 'src/services/api';
import ModalLayout from 'src/components/ModalLayout';
import QuestionComponent from 'src/components/ui/Question';
import { LoginStatus } from 'src/config';


// ----------------------------------------------------------------------

export default function VerifyCodeForm({ goToVerify, goToLogin, setStateVerify, codeMelli, expireDate, phone, sideQuery }) {

  const ModalLayoutQuestionInputRef = useRef();

  const navigate = useNavigate();
  const { publicLogin, publicVerify, handleAddSessionAndAfterLogin } = useAuth();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const { handleStart, timer, isActive } = useTimer();
  const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params);
  const [FormsArr, setFormsArr] = useState([])
  const [VerifyResult, setVerifyResult] = useState({})

  console.log({ expireDate, timer, isActive });
  useEffect(() => {
    if (expireDate) handleStart((+new Date(expireDate) - +new Date()) / 1000)
  }, [codeMelli, expireDate, phone])

  // const { enqueueSnackbar } = useSnackbar();

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().nullable().required('Code is required'),
    code2: Yup.string().nullable().required('Code is required'),
    code3: Yup.string().nullable().required('Code is required'),
    code4: Yup.string().nullable().required('Code is required'),
    // code5: Yup.string().nullable().required('Code is required'),
    // code6: Yup.string().nullable().required('Code is required'),
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
  };

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const values = watch();

  useEffect(() => {
    document.addEventListener('paste', handlePasteClipboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      const code = Object.values(data).join('')
      console.log('* * * onSubmit :', { code });

      // enqueueSnackbar('Verify success!');

      const resData = await publicVerify(codeMelli, code);

      const helpRes = resData?.data;

      setVerifyResult(helpRes);

      const travelsForm = sideQuery?.data?.data || {};
      const finalFormsArr = [];
      Object.values(travelsForm)?.map((x) => {
        console.log('* * * useMemo : ', { x });
        x?.map((y) => {
          const forms = y.forms?.map((z) => ({
            title: `${y?.name} - ${z.name}`,
            path: `${PATH_DASHBOARD.form.root(y.type, y.id)}/${z.name === "ثبت نام اولیه" ? "first" : z.id}`,
          }));
          if (forms?.length) finalFormsArr.push(...forms);
        });
      });

      setFormsArr(finalFormsArr);

      console.log({ resData, helpRes, finalFormsArr });



      if (LoginStatus?.[helpRes?.has_won_lottery]?.mode === "justResult") {
        goToLogin({
          hasError: true,
          error: LoginStatus?.[helpRes?.has_won_lottery]?.text || "",
          type: LoginStatus?.[helpRes?.has_won_lottery]?.type || "info",
        })
        return
      }

      if (LoginStatus?.[helpRes?.has_won_lottery]?.mode === "editRegister") {
        handleQuestionModal()
        return
      }

      if (LoginStatus?.[helpRes?.has_won_lottery]?.mode === "noRegister") {
        if (finalFormsArr?.length > 0) {
          navigate(finalFormsArr[0].path + `?phone=${phone}&code_melli=${codeMelli}`)
        }
        return
      }

      if (LoginStatus?.[helpRes?.has_won_lottery]?.mode === "openRegisterCanLogin") {
        dispatch(getAdminProfile({ isClient: true }));
        navigate(PATH_DASHBOARD.root, { replace: true });
        return
      }

      if (LoginStatus?.[helpRes?.has_won_lottery]?.mode === "openRegisterCantLogin") {
        goToLogin({
          hasError: true,
          error: LoginStatus?.[helpRes?.has_won_lottery]?.text || "",
          type: LoginStatus?.[helpRes?.has_won_lottery]?.type || "info",
        })
        return
      }

      goToLogin({
        hasError: true,
        error: "اطلاعات شما یافت نشد",
        type: "error",
      });

      // if (helpRes?.has_won_lottery === 3) {

      //   // if (finalFormsArr?.length > 0) {
      //   //   navigate(finalFormsArr[0].path)
      //   // }

      //   handleQuestionModal()

      //   return
      // }
      // if (helpRes?.has_won_lottery === 2) {
      //   if (finalFormsArr?.length > 0) {
      //     navigate(finalFormsArr[0].path + `?phone=${phone}&code_melli=${codeMelli}`)
      //   }
      //   return
      // }
      // if (helpRes?.has_won_lottery === 1) {
      //   dispatch(getAdminProfile({ isClient: true }));
      //   navigate(PATH_DASHBOARD.root, { replace: true });
      // }
      // if (helpRes?.has_won_lottery === 0) {
      //   goToLogin({
      //     hasError: true,
      //     error: "متاسفانه شما در قرعه کشی این مرحله انتخاب نشده اید."
      //   })
      // }

      /*if (helpRes?.can_see_panel === 1 || helpRes?.can_see_panel === "1") {

      } else {
        if (helpRes?.has_won_lottery === 3) {
          // if (finalFormsArr?.length > 0) {
          //   navigate(finalFormsArr[0].path)
          // }
          handleQuestionModal()

          return
        }
        if (helpRes?.has_won_lottery === 2) {
          if (finalFormsArr?.length > 0) {
            navigate(finalFormsArr[0].path + `?phone=${phone}&code_melli=${codeMelli}`)
          }
          return
        }
        if (helpRes?.has_won_lottery === 1) {
          dispatch(getAdminProfile({ isClient: true }));
          navigate(PATH_DASHBOARD.root, { replace: true });
        }
        if (helpRes?.has_won_lottery === 0) {
          goToLogin({
            hasError: true,
            error: "متاسفانه شما در قرعه کشی این مرحله انتخاب نشده اید."
          })
        }
      }*/

    } catch (error) {
      console.error(error);
    }
  };

  const handlePasteClipboard = (event) => {
    let data = event?.clipboardData?.getData('Text') || '';

    data = data.split('');

    [].forEach.call(document.querySelectorAll('#field-code'), (node, index) => {
      node.value = data[index];
      const fieldIndex = `code${index + 1}`;
      setValue(fieldIndex, data[index]);
    });
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;
    const fieldIndex = name.replace('code', '');

    const fieldIntIndex = Number(fieldIndex);

    console.log({ maxLength, value, name })

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextField = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);
        if (nextField !== null) {
          nextField.focus();
        }
      }
    }

    handleChange(event);
  };

  const handleGoToFirstForm = async () => {
    await handleAddSessionAndAfterLogin(VerifyResult?.token);
    if (FormsArr?.length > 0) {
      navigate(FormsArr[0].path)
    }
  }

  const handleCloseAndGoToLogin = async () => {
    await handleQuestionModal();
    setStateVerify(false);
  }

  const onResendSubmit = async () => {
    try {
      setLoading(true)
      const resData = await publicLogin(codeMelli, phone);
      console.log({ resData });
      goToVerify({
        codeMelli,
        phone,
        expireDate: +(new Date()) + 120000
      })
      setLoading(false)

    } catch (error) {
      console.log({ error });
      setLoading(false)

      // setError('afterSubmit', { ...error, message: error?.response?.data?.message });
    }
  };

  return (
    <>
      <ModalLayout ref={ModalLayoutQuestionInputRef}>
        <QuestionComponent
          {...{
            title: "آیا مایل هستید اطلاعات خود را ویرایش کنید؟",
            description: "شما قبلا در سامانه ثبت نام کرده اید ، آیا مایل به ویرایش اطلاعات ثبت شده هستید؟",
            button: {
              confirm: {
                label: 'question.yesProcess',
                onClick: () => handleGoToFirstForm(),
              },
              reject: {
                label: 'question.no',
                onClick: () => handleCloseAndGoToLogin(),
              },
            },
          }}
        // onClose={() => handleQuestionModal()}
        />
      </ModalLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{
          direction: "rtl !important"
        }} >
          <Stack sx={{
            direction: "rtl"
          }} direction="row" alignItems={"center"} spacing={2} gap={2} justifyContent="center">
            {Object.keys(values).map((name, index) => (
              <Controller
                key={name}
                name={`code${index + 1}`}
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    id="field-code"
                    autoFocus={index === 0}
                    placeholder="-"
                    onChange={(event) => handleChangeWithNextField(event, field.onChange)}
                    sx={{
                      mr: "0px !important",
                      ml: "0px !important"
                    }}
                    inputProps={{
                      maxLength: 1,
                      sx: {
                        p: 0,
                        textAlign: 'center',
                        width: { xs: 36, sm: 56 },
                        height: { xs: 36, sm: 56 },
                        mr: "0px !important",
                        ml: "0px !important"
                      }
                    }}
                  />
                )}
              />
            ))}
          </Stack>
        </Box>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3
        }}>
          {
            loading ?
              <Box
                sx={{
                  cursor: "pointer",
                  color: 'text.secondary',
                  display: "flex",
                  alignItems: "center",
                  gap: 1
                }}
              >
                <AutorenewIcon />
                {"لطفا منتظر بمانید"}
              </Box>
              :

              isActive ? <Box
                sx={{
                  cursor: "pointer",
                  color: 'text.secondary',
                  display: "flex",
                  alignItems: "center",
                  gap: 1
                }}
              >

                {`زمان باقی مانده ${timer?.minutes}:${timer.seconds}`}
              </Box>
                :
                <Box
                  sx={{
                    cursor: "pointer",
                    color: 'text.secondary',
                    display: "flex",
                    alignItems: "center",
                    gap: 1
                  }}
                  onClick={onResendSubmit}
                >
                  <AutorenewIcon />
                  {"ارسال مجدد"}
                </Box>
          }


          {/* <Typography 
    onClick={goToLogin}
    sx={{
      cursor:"pointer",
  color: 'text.secondary'
    }}>
    {"بازگشت"}
    </Typography> */}
        </Box>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color='success'
          loading={isSubmitting}
          // disabled={!isValid || !isActive}
          disabled={!isValid}
          sx={{ mt: 3 }}
        >
          {"ورود"}
        </LoadingButton>

        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3
        }}>
          <Typography>
            {/* {"ارسال مجدد"} */}
          </Typography>

          <Typography
            onClick={goToLogin}
            sx={{
              cursor: "pointer",
              color: 'text.secondary'
            }}>
            {"بازگشت"}
          </Typography>
        </Box>
      </form>
    </>
  );
}
