import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

RejectionFiles.propTypes = {
  fileRejections: PropTypes.array,
};

export default function RejectionFiles({ fileRejections }) {
  const getErrorMessage = (error) => {
    if(error.code === "file-invalid-type"){
      return "فایل انتخاب شده باید یکی از فرمت های PNG ، JPG ، JPEG باشد."
    }
    if(error.code === "file-too-large"){
      return "عکس انتخابی باید کمتر از 500 کیلوبایت باشد"
    }
    return "فایل انتخابی معتبر نیست"
    // return 
    //   switch (error.code) {
    //     case "file-invalid-type":
    //       return "Unsupported file type. Please upload a .png, .jpg, or .jpeg file.";
    //     case "too-many-files":
    //       return "Only one file can be uploaded at a time.";
    //     case "file-too-large":
    //       return "File is too large. Please upload a smaller file.";
    //     default:
    //       return "File upload error.";
    //   }
    
  };
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>

            {errors.map((error) => (
              <Typography key={error.code} variant="caption" component="p">
                {/* {error.message} */}
                {getErrorMessage(error)}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}
