// @mui
// import { useTheme } from '@mui/material/styles';

import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
// hooks
import { useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import AddCourse from '../Course/Add';
import ModalLayout from '../../components/ModalLayout';
import maskPhoneNumber from '../../utils/maskPhoneNumber';
import VerifyCodeForm from '../../sections/public/auth/verify-code/VerifyCodeForm';
import { LoginForm } from '../../sections/public/auth/login';
import { useSelector } from 'react-redux';
import axiosInstance from 'src/utils/axios';
import api from 'src/services/api';
import { useMutationCustom, useQueryCustom } from 'src/utils/reactQueryHooks';
import { Link, useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import Label from 'src/components/Label';
import { LoadingButton } from '@mui/lab';
import QuestionComponent from 'src/components/ui/Question';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import NormalPeople from './dashboardText/normalPeople';
import SalonPeople from './dashboardText/salonPeople';
// import AppAreaAllRegistered from '../../sections/@dashboard/general/app/AppAreaAllRegistered';
// sections

// ----------------------------------------------------------------------

export default function GeneralAppAdmin() {
  const ModalLayoutCancelQuestionInputRef = useRef();

  const { user, isAuthenticated , logout } = useAuth();
  const queryClient = useQueryClient();


  const admin = useSelector((store) => store.admin.data);
  const reduxTravel = useSelector((store) => store.travel.data);
  const [LoginError, setLoginError] = useState("")

  const navigate = useNavigate();


  const [stateVerify, setStateVerify] = useState()

  const handleCancelQuestionModal = (params) => ModalLayoutCancelQuestionInputRef.current.show((p) => !p, params);

  const gettingHistorySide = async () => axiosInstance.get(api.client.travel.base, {});

  // -------------------------------------------------------- query Get
  const getApi = async () =>
    axiosInstance.get(`${api.notification?.base}`, {
      params: {
        page: 1,
        limit: 10,
        seen: 0,
      },
    });
  const getClientStatus = async () =>
    axiosInstance.get(`${api.status?.base}`);
  // ----------------------------
  const { data: { data = {} } = {}, isLoading } = useQueryCustom({
    name: `${api.notification?.base}_list`,
    url: getApi,
    enabled: !!admin?.id
  });
  const clientStatus = useQueryCustom({
    name: `${api.status?.base}_home`,
    url: getClientStatus,
    enabled: !!admin?.id
  });
  // -------------------------------------------------------- query Get


  const sideQuery = useQueryCustom({
    name: `${api.client.travel.base}_get_home`,
    url: gettingHistorySide,
    enabled: !isAuthenticated
  });

  const goToVerify = (data) => {
    setStateVerify(data)
  }
  const goToLogin = ({
    hasError = false,
    error = "",
    type = "info"
  }) => {
    if (hasError) {
      setLoginError({
        text : error,
        type
      })
    } else {
      setLoginError({
        text : "",
        type
      })
    }
    setStateVerify(false)
  }

  const cancelApi = (params) => axiosInstance.post(api.status.change, params);
  const cancelTravel = useMutationCustom({
    url: cancelApi,
    name: `${api.status.change.base}_enseraf`,
    // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
    onSuccess: async (resData) => {
      handleCancelQuestionModal();
      toast.success(`شما از سفر ${Object.values(reduxTravel)?.[0]?.[0]?.name} انصراف دادید!`)
      await logout();
      queryClient.invalidateQueries(`${api.client.travel.base}_get`)
      navigate(PATH_AUTH.login, { replace: true });
    },
    //  onError: onErrorMutating,
  });

  const handleSubmitCancelTravel = () => {
    console.log({
      travel : Object.values(reduxTravel)?.[0]?.[0]
    })
    cancelTravel.mutate({
      travel_id: Object.values(reduxTravel)?.[0]?.[0]?.id
    })
  }


  // const theme = useTheme();
  const { themeStretch } = useSettings();
  console.log({ user });
  const ModalSectionFormRef = useRef();
  const handleToggleNewCourseModal = () => ModalSectionFormRef.current.show((p) => !p);

  const topSectionData = [
    {
      label: 'حج عمره',
    },
    {
      label: 'حج تمتع',
    },
    {
      label: 'عتیات عالیات',
    },
    {
      label: 'سفرهای زیارتی دیگر',
    },

    // {
    //   label: 'ساخت دوره ثبت نامی',
    //   onClick: handleToggleNewCourseModal,
    // },
  ];

  return (
    <Page title="General: App">
      <ModalLayout ref={ModalSectionFormRef}>
        <AddCourse />
      </ModalLayout>
      <ModalLayout ref={ModalLayoutCancelQuestionInputRef}>
        <QuestionComponent
          {...{
            title: 'آیا از انصراف این سفر مطمئن هستید؟',
            description: `پس از تایید ، شما دیگر در این سفر شرکت نمیکنید و اسم شما از لیست زائران خارج میشود، آیا ادامه میدهید؟`,
            button: {
              confirm: {
                label: 'question.yesProcess',
                onClick: (_, data) => handleSubmitCancelTravel(data),
              },
              reject: {
                label: 'question.no',
                onClick: () => handleCancelQuestionModal(),
              },
            },
          }}
          loading={cancelTravel.isLoading}
        // onClose={() => handleQuestionModal()}
        />
      </ModalLayout>
      <Container maxWidth={themeStretch ? false : 'xl'}>

        {(!isAuthenticated) ?
          <>
            <Box sx={{
              width: '100%',
              maxWidth: "450px",
              mx: "auto"
            }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                {stateVerify ? <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    {`تایید ورود`}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>{`رمز عبور برای شما به شماره موبایل ${maskPhoneNumber(stateVerify?.phone)} ارسال شد.`}</Typography>
                </Box> :
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h4" gutterBottom>
                      {`خوش آمدید`}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary' }}>{'اطلاعات خود را وارد کنید'}</Typography>
                  </Box>
                }

                {/* <Tooltip title={capitalCase(method)} placement="right">
                  <>
                    <Image
                      disabledEffect
                      src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                      sx={{ width: 32, height: 32 }}
                    />
                  </>
                </Tooltip> */}
              </Stack>

              {stateVerify ?
                <>
                  <VerifyCodeForm goToLogin={goToLogin} goToVerify={goToVerify} setStateVerify={setStateVerify} sideQuery={sideQuery} {...stateVerify} />
                </>
                :
                <>
                  <LoginForm goToVerify={goToVerify} sideQuery={sideQuery} LoginError={LoginError} />
                </>
              }
            </Box>
          </>
          :
          <>
            <Typography variant="h4">
              {user?.payload ? `${user?.payload?.name || "دانشجوی"} ${user?.payload?.last_name || ""} گرامی خوش آمدید !` : `به پنل لبیک خوش آمدید.`}
            </Typography>


            {clientStatus.data?.data?.register &&
              <>
                <Stack direction={{md:"row" , xs:"column"}} justifyContent={"space-between"} gap={1} alignItems={"center"}>
                  <Typography
                    sx={{
                      mt: 2,
                      fontWeight: 700
                    }}
                  >
                    <Label
                      variant={'ghost'}
                      color={
                        (clientStatus.data?.data?.register?.status_num === 0 && "info") ||
                        (clientStatus.data?.data?.register?.status_num === 1 && "success") ||
                        (clientStatus.data?.data?.register?.status_num === 3 && "warning") ||
                        (clientStatus.data?.data?.register?.status_num === 6 && "warning") ||
                        (clientStatus.data?.data?.register?.status_num === 7 && "error") || "info"
                      }
                      sx={{
                        fontSize: 15,
                        p: 3,
                        px: 1
                      }}
                    >
                      {(() => {
                        if (clientStatus.data?.data?.register?.status_num === 0) {
                          return "ثبت نام شما با موفقیت انجام شد"
                        }
                        if (clientStatus.data?.data?.register?.status_num === 1) {
                          return "شما به عنوان زائر اصلی پذیرفته شده اید"
                        }
                        if (clientStatus.data?.data?.register?.status_num === 3) {
                          return "شما به عنوان زائر ذخیره پذیرفته شده اید"
                        }
                        if (clientStatus.data?.data?.register?.status_num === 6) {
                          return "شما به عنوان زائر ذخیره 2 پذیرفته شده اید"
                        }
                        if (clientStatus.data?.data?.register?.status_num === 7) {
                          return "شما از این سفر انصراف داده اید"
                        }
                        return clientStatus.data?.data?.register?.status
                      })()}
                    </Label>

                  </Typography>
                  <Stack direction={"row"} justifyContent={"center"} gap={1} my={3}>
                    <LoadingButton
                      variant='contained'
                      color='error'
                      onClick={() => handleCancelQuestionModal()}
                    >
                      انصراف از سفر
                    </LoadingButton>

                    {(clientStatus.data?.data?.register && !clientStatus.data?.data?.register?.sabt_nahayi_status_num) && user?.payload?.name &&
                      <>
                        <Button
                          variant='contained'
                          color='success'
                          component={Link}
                          to={`${PATH_DASHBOARD.form.root(Object.values(reduxTravel)?.[0]?.[0]?.type, Object.values(reduxTravel)?.[0]?.[0]?.id)}/${Object.values(reduxTravel)?.[0]?.[0]?.forms?.[1]?.id}`}
                        >
                          نهایی کردن ثبت نام
                        </Button>
                      </>
                    }

                  </Stack>
                </Stack>

              </>
            }
            {data?.meta?.total > 0 &&
              <Typography
                component={Link}
                to={PATH_DASHBOARD.notification.root}
                color={"error"}
                sx={{
                  display: "block",
                  mt: 2
                }}
              >
                شما {data?.meta?.total} پیام خوانده نشده دارید، برای مشاهده کلیک کنید.
              </Typography>
            }

            {admin?.has_won_lottery == 6 &&
              <>

                <Box
                  sx={{
                    mt: 3
                  }}
                >
                  {(clientStatus.data?.data?.register && clientStatus.data?.data?.register?.client?.type === "قرعه کشی سالن") ?
                  
                    <SalonPeople />
                  :
                  
                    <NormalPeople />
                  }

                  {/* <Typography sx={{
                    fontWeight: 700
                  }}>
                    اگر شرایط اعزام را ندارید از دکمه انصراف استفاده کنید و از این سفر انصراف دهید .
                  </Typography> */}
                  
                </Box>

              </>
            } 
          </>
        }


        {/* <Grid mt={2} container spacing={4}>
          {topSectionData?.map?.((x) => {
            return (
              <Grid item xs={12} md={3}>
                <Box
                  onClick={x.onClick}
                  sx={{
                    bgcolor: 'grey.700',
                    color: 'common.white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                >
                  <Typography>{x.label}</Typography>
                </Box>
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <AppAreaAllRegistered />
          </Grid>
        </Grid> */}

        {/* <Box></Box> */}
      </Container>
      {/* {'welcome'} */}
      {/* <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <AppWelcome displayName={user?.displayName} />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppFeatured />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Active Users"
              percent={2.6}
              total={18765}
              chartColor={theme.palette.primary.main}
              chartData={[5, 18, 12, 51, 68, 11, 39, 37, 27, 20]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Installed"
              percent={0.2}
              total={4876}
              chartColor={theme.palette.chart.blue[0]}
              chartData={[20, 41, 63, 33, 28, 35, 50, 46, 11, 26]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Downloads"
              percent={-0.1}
              total={678}
              chartColor={theme.palette.chart.red[0]}
              chartData={[8, 9, 31, 8, 16, 37, 8, 33, 46, 31]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentDownload />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppAreaInstalled />
          </Grid>

          <Grid item xs={12} lg={8}>
            <AppNewInvoice />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTopRelated />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTopInstalledCountries />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTopAuthors />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Stack spacing={3}>
              <AppWidget title="Conversion" total={38566} icon={'eva:person-fill'} chartData={48} />
              <AppWidget title="Applications" total={55566} icon={'eva:email-fill'} color="warning" chartData={75} />
            </Stack>
          </Grid>
        </Grid>
      </Container> */}
    </Page>
  );
}
