import { Box, Stack, Typography } from "@mui/material";

/* eslint-disable arrow-body-style */
const SalonPeople = () => {
    return (
        <>
            <Stack direction={"column"} gap={1.5}>

                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: 20
                    }}
                >
                    دستورالعمل اجرائی ثبت نام و اعزام
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: 17
                    }}
                >
                    فرهیخته گرامی
                </Typography>

                <Typography
                    sx={{
                        fontSize: 15
                    }}
                >
                    با سلام و آرزوی توفیق الهی
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    در راستای عمل به منویات مقام معظم رهبری مبنی بر تقویت مبانی فکری، اعتقادی و معنویت در جامعه اسلامی و بر اساس راهبرد «نظام فرهنگی ـ تربیتی» سند دانشگاه اسلامی، اعزام کاروان های دانشگاهی به عمره مفرده با اختصاص سهمیه ویژه و بدون نیاز به فیش ودیعه عمره صورت می گیرد. این حرکت عظیم فرهنگی ـ دینی به همت ستاد عمره و عتبات دانشگاهیان با همکاری مسئولان محترم بعثه مقام معظم رهبری، سازمان حج و زیارت، مراکز آموزش عالی کشور و نهاد نمایندگی مقام معظم رهبری در دانشگاه ها در دوره بیستم از بهمن ماه سال جاری آغاز خواهد شد.
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    با توجه به پذیرفته شدن شما از میان خیل مشتاقان این سفر(بالغ بر 130هزار نفر متقاضی) ضمن تبریک انتخاب شما در قرعه كشي عمره 1403، خواهشمند است به دقت مطالب و مراحل زير را مطالعه نموده ، و در صورت داشتن شرایط ذکر شده برای اعزام ، نسبت به نهایی کردن ثبت نام خود اقدام نمائید.
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                    color={"error"}
                >
                    فرصت استفاده از سهمیه عمره دانشگاهیان برای هر شخص فقط یکبار می باشد، افرادی که قبلا(دانشجو یا استاد) با کاروان دانشگاهی اعزام شده اند؛ امکان اعزام مجدد را نخواهند داشت.
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    ابتدا وضعیت اولویت تشرف را ملاحظه نموده(اصلی/ذخیره) سپس نسبت به تکمیل مراحل اقدام نمائید.
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    تذکر- اعزام افراد ذخیره بر اساس اولویت تحویل مدارک و مشروط به انصراف افراد اصلی می باشد.
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    پذیرفته شدگان اصلی و ذخیره می بایست پس از نهایی کردن ثبت نام جهت دریافت گذرنامه و گواهی اشتغال به تحصیل/تدریس اقدام  نمایند.
                </Typography>

                <Typography
                    sx={{
                        fontSize: 17,
                        fontWeight: 700
                    }}
                >
                    گذرنامه
                </Typography>

                <Box
                    sx={{
                        pl: 1.5
                    }}
                >

                    <Typography
                        sx={{
                            lineHeight: 2,
                            fontSize: 15
                        }}
                    >
                        جهت سفر عمره داشتن گذرنامه بین المللی با اعتبار حداقل 6ماه (تا پایان شهریور1404) ضروری می باشد.
                    </Typography>
                    <Typography
                        sx={{
                            lineHeight: 2,
                            fontSize: 15
                        }}
                    >
                        با گذرنامه زیارتی (حتی در صورت داشتن اعتبار) امکان سفر عمره وجود ندارد.
                        دانشجویان پسر مشمول قبل از اقدام جهت دریافت گذرنامه می بایست فرم های مربوط به مجوز خروج از کشور در سامانه را بارگذاری نمایند تا از طریق تفاهم نامه ستاد عمره و عتبات دانشگاهیان با سازمان وظیفه عمومی، بدون نیاز به وثیقه مجوز خروج ایشان صادر گردد.
                        <br />
                        در قسمت مربوط به بارگذاری مدارک؛ عکس پرسنلی مشابه عکس گذرنامه خود را بارگذاری نمائید. برای دریافت فایل عکس گذرنامه می توانید با مراجعه به سایت سخا(<a href="https://sakha.epolice.ir" target="_blank" rel="noreferrer">https://sakha.epolice.ir</a>) عکس خود را دریافت و سپس در سامانه بارگذاری نمائید.
                    </Typography>
                </Box>

                <Typography
                    sx={{
                        fontSize: 17,
                        fontWeight: 700
                    }}
                >
                    گواهی اشتغال به تحصیل/تدریس:
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15,
                    }}
                >
                    ارائه گواهی اشتغال به تحصیل برای دانشجویان و گواهی اشتغال به تدریس یا حکم کارگزینی برای اساتید محترم ضروری بوده و این گواهی می بایست دارای مهر دانشگاه باشد.
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15,
                    }}
                >
                    توجه: درصورتیکه همسر یا فرزند متقاضی؛ دانشگاهی باشد، ارائه مدارک مرتبط(گواهی اشتغال به تحصیل/تدریس) ضروری می باشد.
                </Typography>

                <Typography
                    sx={{
                        fontSize: 17,
                        fontWeight: 700
                    }}
                >
                    هزینه سفر
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    هزینه سفر عمره دانشگاهیان برای هر نفر حدود 45 میلیون تومان می باشد. و حدود 2 هفته مانده به اعزام می بایست کل مبلغ هزینه سفر واریز گردد.
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    بنا بر اعلام سازمان حج و زیارت؛ هزینه پرداختی گروه سنی  2تا12 سال معادل  90درصد هزینه بزرگسال، و زیر 2سال 15 درصد هزینه بزرگسال می باشد. ضمناً منظور از بزرگسال سن بالای 12 سال می باشد.
                    <br />
                    توجه: گروه سنی از زمان روز پرواز(بهمن ماه) محاسبه می گردد.
                </Typography>

                <Typography
                    sx={{
                        fontSize: 17,
                        fontWeight: 700
                    }}
                >
                    زمان اعزام:
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    اعزام کاروان های دانشگاهیان از یکم بهمن 1403 لغایت سی ام بهمن 1403  خواهد بود. زمان تقریبی گروه های اعزامی:
                    <br />
                    دختران مجرد دانشجو: دهه اول بهمن
                    <br />
                    متاهلین و اساتید: دهه دوم بهمن
                    <br />
                    پسران دانشجو: دهه سوم بهمن
                    <br />
                    ضمنا اعزام افراد برگزیده قرعه کشی داخل سالن(سهمیه اعطایی ریاست محترم سازمان حج) ؛ ممکن است به فروردین ماه سال آینده موکول گردد. 
                    <br />
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    توجه: امکان تغییر در برنامه پروازی اعلام شده وجود دارد که در صورت جابه جایی اطلاع رسانی خواهد شد.
                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    تذکر- لازم به ذكر است عدم نهايي كردن ثبت نام، عمل به زمانبندی ها و تحويل به موقع مدارك در مهلت اعلام شده ، به منزله انصراف شخص از اعزام بوده و افراد ذخيره جايگزين خواهند شد .

                </Typography>

                <Typography
                    sx={{
                        lineHeight: 2,
                        fontSize: 15
                    }}
                >
                    درصورتیکه شرایط فوق برای اعزام را نداشته و یا تمایل به اعزام ندارید لطفا از لینک انصراف برای ثبت انصراف خود اقدام نمایید .
                </Typography>


            </Stack>
        </>
    );
}

export default SalonPeople;