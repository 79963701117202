import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { SeverErrorIllustration } from '../assets';
import { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

async function getIPAddress() {
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  return data.ip;
}

// ----------------------------------------------------------------------

export default function Page500({ error, errorInfo }) {

  const [ip, setIP] = useState('');
  const [deviceInfo, setDeviceInfo] = useState({
    browser: '',
    version: '',
    os: '',
    device: '',
  });

  function getDeviceInfo() {
    const parser = new UAParser();
    const result = parser.getResult();
    return {
      browser: result.browser.name || 'Unknown',
      version: result.browser.version || 'Unknown',
      os: result.os.name || 'Unknown',
      device: result.device.model || 'Desktop',
    };
  }

  useEffect(() => {
    // Fetch IP address
    getIPAddress().then(setIP);

    // Fetch device information
    setDeviceInfo(getDeviceInfo());
  }, []);
  return (
    <Page title="500 Internal Server Error" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              500 خطای سرور داخلی
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>خطایی وجود داشت، لطفاً بعداً دوباره امتحان کنید.</Typography>
            {error &&
              <>
                <Typography sx={{ color: 'text.secondary' }}>
                  {error.toString()}
                </Typography>
                <p><strong>IP:</strong> {ip}</p>
                <p><strong>مرورگر:</strong> {deviceInfo.browser}</p>
                <p><strong>نسخه مرورگر:</strong> {deviceInfo.version}</p>
                <p><strong>سیستم عامل:</strong> {deviceInfo.os}</p>
                <p><strong>دستگاه:</strong> {deviceInfo.device}</p>
                {/* {errorInfo && (
                  <Box
                    sx={{
                      maxHeight:"400px",
                      overflow:"auto"
                    }}
                  >
                    <pre style={{ textAlign: 'left', margin: '20px auto', maxWidth: '600px', overflow: 'auto' }}>
                      {errorInfo?.componentStack}
                    </pre>
                  </Box>
                )} */}

              </>
            }

            {/* <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} /> */}
            <Box
              sx={{
                my: 4,
              }}
            />
            <Button to="/" size="large" variant="contained" color="success" component={RouterLink}>
              {'بازگشت به خانه'}
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
