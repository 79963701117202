import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../../components/hook-form';
import { getAdminProfile } from '../../../../redux/slices/user';
import errorsText from '../../../../utils/errorsText';
import axiosInstance from 'src/utils/axios';
import { useQueryCustom } from 'src/utils/reactQueryHooks';
import api from 'src/services/api';
import { NATIONAL_CODE_REGEX, PERSIAN_NUMBER_REGEX } from 'src/config';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';

// ----------------------------------------------------------------------

export default function LoginFormPublic({goToVerify , LoginError , sideQuery}) {
  const { publicLogin } = useAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    code_melli: Yup.string().matches(NATIONAL_CODE_REGEX, errorsText.invalidValue("کدملی")).required(errorsText.blankError()),
    // password: Yup.string().required(errorsText.blankError()),
    password: Yup.string()
    .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("تلفن همراه")) // Accepts Persian and Arabic numerals
    .max(11, errorsText.maxLength(11, "تلفن همراه"))
    .required(errorsText.blankError()),
  });

  const defaultValues = {
    // email: 'admin@gmail.com',
    code_melli:'',
    password: '',
    
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      console.log({ data });
    const resData=  await publicLogin(persianToEnglishNumber(data.code_melli), persianToEnglishNumber(data.password));
    console.log({resData});
    goToVerify({
      phone:persianToEnglishNumber(data.password),
      codeMelli:persianToEnglishNumber(data.code_melli),
      expireDate:+(new Date())+240000
    })
      // dispatch(getAdminProfile());
      // navigate(PATH_DASHBOARD.auth.verify)
    } catch (error) {
      console.log({ error });

      const travelsForm = sideQuery?.data?.data || {};
      const finalFormsArr = [];
      Object.values(travelsForm)?.map((x) => {
        console.log('* * * useMemo : ', { x });
        x?.map((y) => {
          const forms = y.forms?.map((z) => ({
            title: `${y?.name} - ${z.name}`,
            path: `${PATH_DASHBOARD.form.root(y.type, y.id)}/${z.name === "ثبت نام اولیه" ? "first" : z.id}`,
          }));
          if (forms?.length) finalFormsArr.push(...forms);
        });
      });

      if(error?.response?.status === 408){
        if (finalFormsArr?.length > 0) {
          navigate(finalFormsArr[0].path + `?phone=${data.password}&code_melli=${data.code_melli}`)
        }
      }


      // reset();
      // if (isMountedRef.current) {
      setError('afterSubmit', { ...error, message: error?.response?.data?.message });
      // }
    }
  };
  console.log({errors});

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        {(LoginError?.text && LoginError?.text !== "") && <Alert severity={LoginError?.type || "error"} icon={<></>} sx={{
          color : LoginError?.type == "success" ? "#fff" : undefined
        }}>{LoginError?.text || ""}</Alert>}

        <RHFTextField name={'code_melli'} label="شماره ملی"  />

        <RHFTextField
          name="password"
          label="تلفن همراه"
          type={showPassword ? 'text' : 'text'}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
          //         <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
          //       </IconButton>
          //     </InputAdornment>
          //   ),
          // }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" 
        color='success'
      
      variant="contained" loading={isSubmitting} sx={{ mt: 2 }}>
        ورود
      </LoadingButton>
    </FormProvider>
  );
}
